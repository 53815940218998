@include keyframes(navbar-input-expand) {
  from {
    width: 360px;
  }

  to {
    width: 420px;
  }
}

@include keyframes(navbar-input-expand-md) {
  from {
    width: 240px;
  }

  to {
    width: 280px;
  }
}

.header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: $header-zindex;
  margin-bottom: 0;
  padding: 15px 15px 0;

  @include transition(box-shadow 0.2s linear);
  @include clearfix();
  @include display-flex();
  @include flex(1);

  @include media-breakpoint-down(sm) {
    display: block;
  }

  & .header-content {
    @include media-breakpoint-up(sm) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  & .navbar-toggle {
    position: relative;
    padding: 10px 10px 10px 0;
    background-color: transparent;
    background-image: none;
    border: none;
    outline: none;

    @include border-radius($border-radius);

    @include media-breakpoint-up(sm) {
      display: none;
    }

    @if $enable-rtl {
      margin-right: 0;
      margin-left: 10px;
    }

    & .icon-bar {
      display: block;
      width: 22px;
      height: 2px;

      @include border-radius($border-radius);

      &+.icon-bar {
        margin-top: 4px;
      }
    }

    &:hover {
      background: none;
      opacity: 0.75;
    }

    &.pull-left {
      margin-left: 10px;
      margin-right: 0;

      @if $enable-rtl {
        margin-right: 10px;
        margin-left: 0;
      }
    }
  }

  & .navbar-header {
    @include clearfix();
    @include display-flex();
    @include flex-align(center);
    // @include media-breakpoint-up(sm) {
    //   width: $sidebar-width;
    // }

    & .navbar-header-a {
      flex: 1;
      justify-content: flex-start;
    }
  }

  & .navbar-brand {
    padding: 10px 20px;
    height: $header-height;
    font-weight: 100;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;

    @include flex-align(center);
    @include display-flex();

    @if $enable-rtl {
      margin-right: 0;
      margin-left: 16px;
    }

    & i {
      @if $enable-rtl {
        margin-left: 10px;
      }

      @else {
        margin-right: 10px;
      }
    }

    & img {
      max-width: 100%;
      max-height: 100%;
    }

    & b {
      font-weight: bold;

      @if $enable-rtl {
        margin-left: 5px;
      }

      @else {
        margin-right: 5px;
      }
    }

    & small {
      font-size: 10px;
      font-weight: 700;
      margin-top: -20px;
    }
  }

  & .navbar-nav {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    overflow: auto;
    list-style-type: none;

    &>li {
      margin-left: 2px;
      margin-right: 2px;

      &>a {
        display: block;
        text-decoration: none;
        border: none;
        font-weight: 600;
        white-space: nowrap;

        & .label {
          position: absolute;
          top: 8px;
          right: 5px;
          display: block;
          background: lighten($red, 8%);
          line-height: 12px;
          font-weight: 700;
          color: $white;
          padding: 3px 6px;

          @include box-shadow(0 2px 6px rgba($dark, 0.5));
          @include border-radius($border-radius-lg * 5);
        }

        & i {
          top: 1px;
          position: relative;
          font-size: 16px;
        }

        &:hover,
        &:focus {
          opacity: 0.75;
        }

        &.icon {
          & i {
            font-size: 24px;
            height: 24px;
            margin: -2px 0;
            display: block;
          }
        }
      }

      &.active {
        &>a {
          background: $light;
          opacity: 1;
        }
      }

      &.dropdown {
        & .dropdown-menu {
          margin: 0;
        }

        &.dropdown-lg {
          position: static;

          & .dropdown-menu {
            left: 20px !important;
            right: 20px !important;
            padding: 20px;

            @include transform(none !important);

            @include media-breakpoint-up(sm) {
              top: 100% !important;
            }

            @include media-breakpoint-down(sm) {
              overflow: scroll;
              top: $header-height-sm * 2 + 1px !important;
              max-height: calc(100vh - #{$header-height-sm * 2 + 1px});
            }

            &.dropdown-menu-lg {
              & .nav {
                &>li {
                  &>a {
                    padding: 0;
                    background: none;
                    line-height: 24px;
                  }
                }
              }
            }
          }
        }
      }

      &.show {

        &>a,
        &>a:hover,
        &>a:focus {
          background: none;
          color: $dark;
          opacity: 1;
        }
      }

      &.divider {
        height: 34px;
        margin-top: 10px;
        background: $light;
        width: 1px;
      }
    }

    &.navbar-right {
      margin-left: auto;

      @if $enable-rtl {
        margin-left: 0;
        margin-right: auto;
      }
    }

    @include display-flex();
    @include flex-direction-row();

    @include media-breakpoint-down(sm) {
      @include flex-justify-content(space-between);
      @include flex(1);

      padding: 8px 0 0;

      &>li {
        margin-left: 0px;
        margin-right: 8px;
      }
    }
  }

  & .navbar-form {
    padding: 0 15px;
    margin: 9px 0;

    @include media-breakpoint-down(sm) {
      padding-left: 5px;
      padding-right: 5px;

      @include flex(1);
    }

    & .form-group {
      margin: 0;
      position: relative;
    }

    & .form-control {
      width: 360px;
      padding: 5px 10px;
      height: 32px;
      background: $light;
      border-color: $light;

      @include border-radius($border-radius-sm);

      @include media-breakpoint-down(sm) {
        width: 240px;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:focus {
        width: 420px;
        border-color: $light;

        @include box-shadow(none);
        @include animation(navbar-input-expand 0.2s);

        @include media-breakpoint-down(sm) {
          width: 280px;

          @include animation(navbar-input-expand-md 0.2s);
        }

        @include media-breakpoint-down(sm) {
          width: 100%;

          @include animation(none);
        }
      }
    }

    & .btn-search {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      border: none;
      background: none;

      @include border-radius(0 $border-radius-lg * 5 $border-radius-lg * 5 0);
      @include display-flex();
      @include flex-align(center);

      @if $enable-rtl {
        right: auto;
        left: 0;
      }

      &>i[class*='ion-'] {
        font-size: 22px;
        display: block;
      }
    }
  }

  & .navbar-user {
    & img {
      float: left;
      width: 30px;
      height: 30px;
      margin: -5px 10px -5px 0;

      @include border-radius($border-radius-lg * 5);
    }

    & .image {
      float: left;
      width: 30px;
      height: 30px;
      margin: -5px 10px -5px 0;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include border-radius($border-radius-lg * 5);

      &.image-icon {
        font-size: 22px;
        text-align: center;

        @include display-flex();
        @include flex-align(center);
        @include flex-justify-content(center);

        & i {
          float: none;
          display: block;
          font-size: 22px;
          text-align: center;
          width: auto;
          margin: 0 auto -8px;
        }
      }
    }
  }

  & .navbar-language {
    & .flag-icon {
      font-size: 15px;

      @if $enable-rtl {
        margin-left: 7px;
      }

      @else {
        margin-right: 7px;
      }
    }
  }

  & .navbar-collapse {
    @include media-breakpoint-up(sm) {
      display: block;

      @include flex-basis(initial);
    }

    @include media-breakpoint-only(sm) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      & .navbar-nav {
        &>li {
          @include flex(1);

          &>a {
            @include display-flex();
            @include flex-align(center);
            @include flex-justify-content(center);
          }
        }
      }
    }
  }

  &.navbar-default {
    background: $white;

    & .navbar-brand {
      color: $dark;

      & i {
        color: $primary;
      }
    }

    & .navbar-toggle {
      & .icon-bar {
        background: $dark;
      }
    }

    & .navbar-nav {
      &>li {
        &>a {
          color: $dark;

          & .label {
            @include box-shadow(none);
          }
        }
      }
    }
  }
}

.has-scroll {
  & .header {
    @include box-shadow(0 0 16px rgba($black, 0.15));
  }
}

.legend {
	& > table {
		& tbody {
			& tr {
				& + tr {
					& td {
						padding-top: 0;
					}
				}
				& td {
					padding: 4px 0 5px;

					&.legendColorBox {
						padding-left: 5px !important;

						& > div {
							margin-right: 5px !important;
							border: none !important;

							@include border-radius($border-radius * 2);

							& > div {
								border-width: 4px !important;

								@include border-radius($border-radius * 2);
							}
						}
					}
					&.legendLabel {
						padding-right: 10px;
						color: $white;
						font-size: 11px;

						@if $enable-rtl {
							padding-right: 0;
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
	& > div {
		background: $dark-darker !important;

		@include border-radius($border-radius);
	}
}
.flot-text {
	& .tickLabel {
		color: $dark;
		font-size: 12px;
		font-weight: 600;
	}
}
.flot-tooltip {
	position: absolute;
	display: none;
	border: 0;
	padding: 6px 10px;
	color: rgba($white, .9);
	font-weight: 600;
	font-size: 11px;
	background: rgba($dark-darker, .85);
	z-index: 10000 !important;

	@include border-radius($border-radius);
}

.page-with-two-sidebar {
  & .sidebar-right {

    &.sidebar,
    &.sidebar-bg {
      @include media-breakpoint-up(md) {
        @if $enable-rtl {
          right: auto;
          left: 0;
        }

        @else {
          right: 0;
          left: auto;
        }
      }
    }
  }

  & .content {
    @include media-breakpoint-up(md) {
      @if $enable-rtl {
        margin-left: $sidebar-width;
      }

      @else {
        margin-right: $sidebar-width;
      }
    }
  }

  &.page-sidebar-minified {
    & .sidebar-right {

      &.sidebar,
      &.sidebar-bg {
        width: $sidebar-width;
      }
    }

    &.page-with-wide-sidebar {
      & .sidebar-right {

        &.sidebar,
        &.sidebar-bg {
          width: $sidebar-wide-width;
        }
      }
    }
  }

  &.page-sidebar-fixed {
    & .sidebar {
      &.sidebar-right {
        position: fixed;
      }
    }
  }
}

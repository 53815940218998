.close {
	margin-left: 15px;
	text-shadow: none;
	line-height: 14px;

	@if $enable-rtl {
		float: left;
		margin-left: 0;
		margin-right: 15px;
	}
}

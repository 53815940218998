
.registered-users-list {
	list-style-type: none;
	margin: 7.5px;
	padding: 0;

	@include display-flex();
	@include flex-wrap(wrap);

	& > li {
		width: 25%;
		font-size: 12px;
		padding: 7.5px;
		color: $dark;

		& > a {
			display: block;
			margin-bottom: 10px;
		}
		& img {
			max-width: 100%;

			@include border-radius($border-radius);
		}
		& .username {
			font-size: 12px;
			color: $dark;
			margin-bottom: 0;
			font-weight: 600;

			& small {
				display: block;
				font-size: 11px;
				color: lighten($dark, 30%);
				line-height: 15px;
			}
		}
	}
}

.btn {
	font-weight: 600;

	&.btn-circle,
	&.btn-rounded {
		@include border-radius(200px);
	}
	&.btn-icon {
		width: 28px;
		height: 28px;
		padding: 0;
		border: none;
		line-height: 28px;
		text-align: center;
		font-size: 14px;

		&.btn-xs {
			width: 16px;
			height: 16px;
			font-size: 8px;
			line-height: 16px;
		}
		&.btn-sm {
			width: 22px;
			height: 22px;
			font-size: 11px;
			line-height: 22px;
		}
		&.btn-lg {
			width: 34px;
			height: 34px;
			font-size: 17px;
			line-height: 34px;
		}
	}
	&.btn-theme {
		padding: 12px 30px;
		font-weight: bold;

		@include border-radius($border-radius-lg);
	}
	&.btn-xs {
		font-size: 12px;
		line-height: 18px;
		padding: 1px 5px;
	}
	&.btn-white {
		&.btn-white-without-border {
			border-color: $white;

			&:not(.active) {
				&:hover,
				&:focus {
					border-color: lighten($dark, 75%) !important;
					background: lighten($dark, 75%) !important;
				}
			}
			&.active {
				border-color: lighten($dark, 70%) !important;
			}
		}
	}
}

.btn-default {
	@include button-variant($white, darken($silver, 10%), $silver, darken($silver, 10%), darken($silver, 10%), darken($silver, 10%));
}

@each $color, $value in $theme-colors {
	@if $color == 'primary' {
		$value: $blue;
	}
  .btn-#{$color} {
    @include button-variant($value, $value, darken($value, 10%), darken($value, 10%), darken($value, 10%), darken($value, 10%));

    @if $color == 'warning' or $color == 'info' or $color == 'grey' {
    	color: $white;
    }
    @if $color == 'grey' {
    	color: $dark;

    	&:hover,
    	&:focus {
    		color: $dark;
    	}
    }
  }
}

@each $color, $value in $colors {
  .btn-#{$color} {
  	@if $color == 'white' {
  		@include button-variant($value, $border-color, lighten($dark, 75%), $border-color, lighten($dark, 70%), $border-color);
  	}
  	@if $color != 'white' {
    	@include button-variant($value, $value, darken($value, 10%), darken($value, 10%), darken($value, 10%), darken($value, 10%));
    }
    @if $color == 'orange' or $color == 'aqua' or $color == 'lime' {
    	color: $white;
    }
    @if $color == 'lime' {
    	&:hover,
    	&:focus {
    		color: $white;
    	}
    }
  }
}

.btn-group,
.input-group-prepend,
.input-group-append {
	& .btn-default {
		& + .btn-default {
			border-left-color: darken($silver, 10%);

			@if $enable-rtl {
				border-right: 1px solid darken($silver, 10%);

				&:not(:last-child):not(.dropdown-toggle) {
					border-left: none;
				}
			}
		}
	}
	@each $color, $value in $theme-colors {
		@if $color == 'primary' {
			$value: $blue;
		}
  	& .btn-#{$color} {
  		& + .btn-#{$color} {
  			border-left-color: darken($value, 10%);

				@if $enable-rtl {
					border-right: 1px solid darken($value, 10%);

					&:not(:last-child) {
						border-left: none;
					}
				}
  		}
  	}
	}
	@each $color, $value in $colors {
		& .btn-#{$color} {
			& + .btn-#{$color} {
				@if $color == 'white' {
					border-left-color: $border-color;

					@if $enable-rtl {
						border-right: 1px solid $border-color;

						&:not(:last-child) {
							border-left: 1px solid $border-color;
						}
					}
				}
				@if $color != 'white' {
					border-left-color: darken($value, 10%);

					@if $enable-rtl {
						border-right: 1px solid darken($value, 10%);

						&:not(:last-child) {
							border-left: none;
						}
					}
				}
			}
		}
	}
}

.btn-group {
	& > .btn {
		@if $enable-rtl {
			@include border-radius(0);

			&:first-child {
				margin-left: -1px;
			}
			&:first-child:last-child {
				@include border-radius($border-radius);
			}
			&:not(:first-child):not(:last-child),
			&:not(:first-child):not(:last-child):not(.dropdown-toggle) {
				@include border-radius(0);
			}
			&:not(:first-child),
			&:not(:first-child):not(.dropdown-toggle),,
			&:not(:first-child).dropdown-toggle {
				@include border-top-left-radius($border-radius);
				@include border-bottom-left-radius($border-radius);
			}
			&:not(:last-child),
			&:not(:last-child):not(.dropdown-toggle) {
				@include border-top-right-radius($border-radius);
				@include border-bottom-right-radius($border-radius);
			}
			& + .btn {
				@include border-top-right-radius(0);
				@include border-bottom-right-radius(0);
			}
		}
	}
	& > .btn-group:not(:first-child) {
		margin-left: 0;
		margin-right: -1px;
	}
}

.irs {
	font-family: inherit !important;

	& .irs {
		& .irs-line {
			height: 4px;
			top: 27px;

			& .irs-line-left,
			& .irs-line-mid,
			& .irs-line-right {
				background: lighten($dark, 70%);
				height: 4px;
			}
			& .irs-line-left {
				@include border-radius($border-radius 0 0 $border-radius);
			}
			& .irs-line-right {
				@include border-radius(0 $border-radius $border-radius 0);
			}
		}
		& .irs-to,
		& .irs-from,
		& .irs-single {
			background: $dark;
		}
	}
	& .irs-bar-edge {
		background: lighten($dark, 70%);
		border-right: 1px solid $white;
		top: 27px;
		height: 4px;

		@include border-radius($border-radius 0 0 $border-radius);
	}
	& .irs-bar {
		background: $form-component-active-bg;
		height: 4px;
		top: 27px;
	}
	& .irs-min,
	& .irs-max {
		font-size: $font-size-base;
		background: lighten($dark, 75%);
		color: lighten($dark, 15%);
		font-weight: 600;
	}
	& .irs-slider {
		background: $white;
		cursor: pointer;

		@include box-shadow(0 1px 3px rgba($black, .25));
		@include border-radius($border-radius * 10);
	}

	&.irs--flat {
		& .irs-bar {
			height: 4px;
			top: 27px;
			background: $form-component-active-bg;
		}
		& .irs-handle {
			height: 14px;

			& > i {
				&:first-child {
					background: $form-component-active-bg;
					width: 4px;
				}
			}
			&:hover,
			&:focus,
			&.state_hover {
				& > i {
					&:first-child {
						background: mix($black, $form-component-active-bg, 50%);
					}
				}
			}
		}
	}
	&.irs--big {
		height: 40px;

		& .irs {
			& .irs-line {
				background: lighten($dark, 70%);
				border: none;
			}
		}
		& .irs-bar {
			height: 4px;
			top: 27px;
			background: $form-component-active-bg;

			@include box-shadow(none);
		}
		& .irs-handle {
			top: 20px;
			width: 20px;
			height: 20px;
			background: $white;
			border: none;

			@include box-shadow(0 2px 6px rgba($black, .25));

			&:hover,
			&:focus,
			&.state_hover {
				background: $white;

				@include box-shadow(0 2px 6px rgba($black, .5));
			}
		}
	}
	&.irs--modern {
		height: 40px;

		& .irs {
			& .irs-line {
				background: lighten($dark, 70%);
				border: none;
			}
		}
		& .irs-bar {
			height: 4px;
			top: 27px;
			background: $form-component-active-bg;

			@include box-shadow(none);
		}
		& .irs-handle {
			width: 12px;
			border: none;
			background: $silver;

			@include box-shadow(none);
			@include border-radius(0 0 $border-radius $border-radius);

			& > i {
				&:nth-child(1) {
					background: none;
					border: 6px solid transparent;
					border-bottom-color: $silver;
					top: -12px;
					left: 0;

					@include transform(none);
				}
				&:nth-child(2) {
					background: $silver;
					left: 1px;

					@include border-radius(0);
				}
				&:nth-child(3) {
					top: 2px;
					left: 4px;
					width: 4px;
					height: 8px;
					border-color: mix($white, $silver, 75%);
				}
			}
			&:hover,
			&:focus,
			&.state_hover {
				background: $silver;

				& > i {
					&:nth-child(1) {
						border: 6px solid transparent;
						border-bottom-color: $silver;
					}
					&:nth-child(2) {
						background: $silver;
					}
					&:nth-child(3) {
						border-color: mix($black, $silver, 50%);
					}
				}
			}
		}
	}
	&.irs--sharp {
		height: 40px;

		& .irs {
			& .irs-line {
				background: lighten($dark, 70%);
				border: none;
			}
		}
		& .irs-bar {
			height: 4px;
			top: 27px;
			background: $form-component-active-bg;

			@include box-shadow(none);
		}
		& .irs-handle {
			top: 23px;
			background: mix($white, $form-component-active-bg, 25%);

			& > i {
				&:first-child {
					border-top-color: mix($white, $form-component-active-bg, 25%);
				}
			}
			&:hover,
			&:focus,
			&.state_hover {
				background: $dark;

				& > i {
					&:first-child {
						border-top-color: $dark;
					}
				}
			}
		}
	}
	&.irs--round {
		height: 40px;

		& .irs-bar {
			height: 4px;
			top: 27px;
			background: $form-component-active-bg;

			@include box-shadow(none);
		}
		& .irs-handle {
			top: 20px;
			width: 20px;
			height: 20px;
			border: 3px solid $form-component-active-bg;

			@include box-shadow(none);
		}
	}
	&.irs--square {
		height: 40px;

		& .irs-bar {
			height: 4px;
			top: 27px;
			background: $form-component-active-bg;

			@include box-shadow(none);
		}
		& .irs-handle {
			top: 21px;
			width: 16px;
			height: 16px;
			border: 3px solid $form-component-active-bg;

			@include box-shadow(none);
		}
	}
	&.irs--flat,
	&.irs--big,
	&.irs--modern,
	&.irs--sharp,
	&.irs--round,
	&.irs--square {
		& .irs-from,
		& .irs-to,
		& .irs-single {
			font-size: 11px;
			font-weight: 600;

			&:before {
				border-top-color: $dark;
			}
		}
		& .irs-min,
		& .irs-max {
			color: $dark;
			font-size: 11px;
			font-weight: 600;
			background: mix($white, $silver, 50%);
		}
	}
}

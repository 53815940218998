.inbox {
	& .nav-title {
		font-size: 11px;
		color: lighten($dark, 45%);
		padding: 15px 15px 7px;
	}
	& .nav-inbox {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: block;

		& > li {
			display: block;

			& + li {
				margin-top: 0;
			}
			& > a {
				display: block;
				text-decoration: none;
				border-radius: 0;
				padding: 8px 20px;
				color: $dark;
				font-weight: 600;

				& i {
					width: 20px;
				}
				& .badge {
					background: lighten($dark, 30%);
					color: $white;
					padding: 4px 8px;
					font-size: 10px;
					line-height: 11px;
				}
			}
			&.active {
				& > a {
					background: lighten($dark, 70%);
					color: $dark;
				}
			}
		}
	}
	& .float-right-link {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;

		@include display-flex();
		@include flex-align(center);
		@if $enable-rtl {
			right: auto;
			left: 0;
		}
	}
	& .email-to {
		position: relative;

		& .tagit {
			border: none !important;
			border-bottom: 1px solid $border-color !important;
			padding: 10px 12px 10px 45px !important;

			@include border-radius(0);
			@if $enable-rtl {
				padding-right: 45px !important;
				padding-left: 12px !important;
			}

			& input {
				font-weight: 500;
			}
		}
		& .form-control {
			border: none !important;
			border-bottom: 1px solid $border-color !important;
			padding: 10px 12px 10px 45px !important;

			@include border-radius(0);
			@if $enable-rtl {
				padding-right: 45px !important;
				padding-left: 12px !important;
			}
		}
		& .control-label {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			line-height: 16px;
			padding-left: 10px;
			width: 45px;
			margin-bottom: 0;

			@include display-flex();
			@include flex-align(center);
			@if $enable-rtl {
				padding-left: 0;
				padding-right: 10px;
				left: auto;
				right: 0;
			}
		}
	}
	& .email-subject {
		& .form-control {
			border: none !important;
			border-bottom: 1px solid $border-color !important;
			padding: 10px 12px 10px 12px !important;

			@include border-radius(0);

			&:focus {
				@include box-shadow(none);
			}
		}
	}
	& .email-content {
		padding: 0;
		margin: 0;

		& .wysihtml5-toolbar {
			padding: 10px 0 0;

			& .btn {
				font-size: 12px;
				margin-bottom: 5px;
			}
			& .btn-group {
				margin-bottom: 5px;

				& .btn {
					margin-bottom: 0;
				}
			}
			& > li {
				margin: 0 5px 5px 0;

				@if $enable-rtl {
					margin-right: 0;
					margin-left: 5px;
				}
			}
		}
		& .wysihtml5-sandbox {
			padding: 15px !important;
			border: 1px solid $border-color !important;

			@include border-radius(0 !important);
		}
	}
	& .attached-document {
		list-style-type: none;
		margin: 15px 0 0;
		padding: 0;

		@include display-flex();
		@include flex-wrap(wrap);

		& > li {
			width: 180px;
			background: $white;
			color: $dark;
			font-weight: 600;
			position: relative;
			margin-right: 15px;
			margin-bottom: 15px;
			border: 1px solid $border-color;

			@if $enable-rtl {
				margin-right: 0;
				margin-left: 15px;
			}

			&:before {
				position: absolute;
				top: -1px;
				right: -1px;
				font-size: 12px;
				background: lighten($dark, 65%);
				width: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;

				@include fontawesome();
			}
			& img {
				max-width: 100%;
			}
			& .document-name {
				padding: 5px 10px;
				text-align: center;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				background: $light;
				border-top: 1px solid $border-color;

				& a {
					color: $dark;
				}
			}
			& .document-file {
				height: 70px;
				background: none;
				overflow: hidden;
				text-align: center;
				line-height: 70px;
				font-size: 32px;
				margin: -1px;
			}
		}
	}
	& .list-email {
		border-top: 1px solid lighten($border-color, 5%);
		border-bottom: 1px solid lighten($border-color, 5%);
		margin: -1px 0;

		& > li {
			&.list-group-item {
				border: none;
				margin-bottom: 0;
				padding: 15px;
				position: relative;
				width: 100%;
				overflow: hidden;

				@include border-radius(0);
				@include clearfix();
				@include display-flex();
				@include flex-align(center);

				& + li {
					&.list-group-item {
						border-top: 1px solid lighten($border-color, 5%);
					}
				}

				& a {
					color: $dark;
					text-decoration: none;
				}
				& .email-info {
					max-width: calc(100% - 46px);

					@include flex(1);

					& > a {
						margin-top: -15px;
						margin-right: -15px;
						margin-bottom: -15px;
						padding: 15px;
						max-width: 100%;

						@include display-flex();
						@include flex-wrap(wrap);
						@if $enable-rtl {
							margin-right: 0;
							margin-left: -15px;
						}
						@include media-breakpoint-down(sm) {
							display: block;
						}
					}
				}
				& .email-user {
					width: 30px;
					height: 30px;
					min-width: 30px;
					overflow: hidden;
					font-size: 18px;
					line-height: 30px;
					text-align: center;
					color: lighten($dark, 30%);
					background: lighten($dark, 60%);
					margin: -5px 0;

					@include border-radius(30px);

					& img {
						max-width: 100%;
						display: block;
					}
				}
				& .email-sender {
					display: block;
					width: 200px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					@include media-breakpoint-down(lg) {
						width: 160px;
					}
					@include media-breakpoint-down(md) {
						width: 120px;
					}
					@include media-breakpoint-down(sm) {
						width: auto;
						font-size: 14px;
						padding-right: 90px;

						@if $enable-rtl {
							padding-right: 15px;
							padding-left: 90px;
						}
					}
				}
				& .email-title {
					display: block;
					line-height: 20px;
					width: 300px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					@include media-breakpoint-up(md) {
						padding-left: 15px;

						@if $enable-rtl {
							padding-left: 0;
							padding-right: 15px;
						}
					}
					@include media-breakpoint-down(lg) {
						width: auto;

						@include flex(1);
					}

					& + .email-desc {
						@include media-breakpoint-up(md) {
							padding-left: 20px;

							@if $enable-rtl {
								padding-left: 0;
								padding-right: 20px;
							}
							&:before {
								content: '';
								position: absolute;
								left: 6px;
								top: 8px;
								width: 4px;
								height: 4px;
								background: lighten($dark, 45%);
								@include border-radius($border-radius-lg);
								@if $enable-rtl {
									left: auto;
									right: 6px;
								}
							}
						}
					}
				}
				& .email-desc {
					display: block;
					position: relative;
					color: lighten($dark, 15%);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					@include flex(1);
					@include media-breakpoint-down(lg) {
						display: none;
					}
					@include media-breakpoint-down(sm) {
						display: block;
						padding-right: 0;
					}
				}
				& .email-time {
					display: block;
					padding: 0 15px 0 0;
					font-size: 11px;
					font-weight: 500;
					color: lighten($dark, 30%);
					text-align: right;
					width: 100px;
					position: absolute;
					right: 0;
					top: 15px;
					height: 20px;
					background: $white;

					@include display-flex();
					@include flex-align(center);
					@include flex-justify-content(flex-end);
					@if $enable-rtl {
						padding-right: 0;
						padding-left: 15px;
						right: auto;
						left: 0;
						text-align: left;
					}
					@include media-breakpoint-down(lg) {
						width: 90px;
					}
				}
				& .email-checkbox {
					& .custom-checkbox {
						width: 16px;
						padding-left: 16px;

						@if $enable-rtl {
							padding-left: 0;
							padding-right: 16px;
						}

						& .custom-control-label {
							&::before,
							&::after {
								left: -16px;

								@if $enable-rtl {
									left: auto;
									right: -16px;
								}
							}
						}
					}
					& + .email-user {
						margin-left: 15px;

						@if $enable-rtl {
							margin-left: 0;
							margin-right: 15px;
						}
					}
				}

				&.unread {
					& .email-title {
						font-weight: 600;
						color: $dark;
					}
					& .email-sender {
						font-weight: 600;
					}
				}
				&.selected {
					background: lighten($yellow, 46%);

					& .email-time {
						background: lighten($yellow, 46%);
					}
				}
			}
		}
	}
}

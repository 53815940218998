.ui-sortable {
	& .panel-heading {
		cursor: move;
	}
	& .ui-sortable-placeholder {
		border: 1px dashed lighten($dark, 45%) !important;
		visibility: visible !important;
		background: none !important;
		margin: 0 !important;

		&.panel {
			margin: -1px -1px 19px !important;

			@include box-shadow(none !important);
		}
	}

	&.ui-sortable-disabled {
		& .panel-heading {
			cursor: default;
		}
	}
}

body {
	& .colorpicker {
		border: none;
		padding: 5px;
		z-index: 1010;

		@include border-radius($border-radius);

		& .colorpicker-hue,
		& .colorpicker-alpha {
			margin-bottom: 5px;
			margin-left: 5px;

			@include border-radius($border-radius);
			@include box-shadow(none);
		}
		& .colorpicker-saturation {
			margin-bottom: 5px;

			@include border-radius($border-radius);
			@include box-shadow(none);

			& i {
				border-color: rgba($black, .25);
			}
		}
		& .colorpicker-color,
		& .colorpicker-alpha {
			@include border-radius($border-radius);
			@include box-shadow(none);

			& div {
				@include border-radius($border-radius);
				@include box-shadow(none);
			}
		}
		& .colorpicker-bar {
			margin-top: 5px;

			@include border-radius($border-radius);
			@include box-shadow(none);

			& div {
				@include border-radius($border-radius);
				@include box-shadow(none);
			}
		}

		&:before {
			display: none;
		}
		&:after {
			border-width: 7px;
			top: -7px;
		}
		&.colorpicker-left {
			&:after {
				left: 12px;
			}
		}
		&.colorpicker-right {
			&:after {
				right: 12px;
			}
		}
		&.colorpicker-with-alpha {
			width: 168px;
		}
	}
	& .colorpicker-component {
		& .input-group-addon {
			@include display-flex();
			@include flex-align(center);
		}
	}
	& .colorpicker-bs-popover {
		& .popover-body {
			padding: 5px;

			& .colorpicker-popup.colorpicker-bs-popover-content {
				padding: 0;
			}
		}
	}
}

.btn-scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 25px;
	z-index: $btn-scroll-to-top-zindex;

	@if $enable-rtl {
		right: auto;
		left: 25px;
	}
}

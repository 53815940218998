.form-horizontal {
	&.form-bordered {
		& .form-group {
			border-bottom: 1px solid lighten($border-color, 5%);
			margin: 0;

			& .col-form-label {
				padding: 15px;
				font-weight: 600;
				color: lighten($dark, 15%);

				@include display-flex();
				@include flex-align(center);
				@include media-breakpoint-down(md) {
					padding-bottom: 8px;
				}

				& + div {
					border-left: 1px solid lighten($border-color, 5%);

					@if $enable-rtl {
						border-left: none;
						border-right: 1px solid lighten($border-color, 5%);
					}
					@include media-breakpoint-down(md) {
						border-left: none;
						padding-top: 0;

						@if $enable-rtl {
							border-right: none;
						}
					}
				}
			}
			& > div {
				padding: 15px;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}

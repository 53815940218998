.page-header {
	font-size: 28px;
	margin: 0 0 8px;
	padding: 0;
	border: none;
	line-height: 32px;
	font-weight: 600;

	& small {
		line-height: 20px;
		font-size: 14px;
	}
}

.page-without-sidebar {
  & .content {
    @if $enable-rtl {
      margin-right: 0;
    }

    @else {
      margin-left: 0;
    }
  }

  & .header {
    & .navbar-brand {
      @include media-breakpoint-down(sm) {
        @include flex-justify-content(center);
      }
    }
  }
}

.widget-chat {
	background: $white;

	& .widget-chat-header {
		display: table;
		width: 100%;
		border-bottom: 1px solid $border-color;

		& > div {
			& + div {
				padding-left: 0;

				@if $enable-rtl {
					padding-right: 0;
					padding-left: 15px;
				}
			}
		}
		& .widget-chat-header-icon {
			padding: 12px 15px;
			display: table-cell;
			vertical-align: middle;
			width: 1%
		}
		& .widget-chat-header-content {
			padding: 12px 15px;
			display: table-cell;
			vertical-align: middle;
		}
		& .widget-chat-header-title {
			font-size: 14px;
			margin: 0;
		}
		& .widget-chat-header-desc {
			font-size: 12px;
			margin: 0;
		}
	}
	& .widget-chat-body {
		padding: 15px;
		background: lighten($dark, 80%);
	}
	& .widget-chat-item {
		color: $dark;
		position: relative;
		min-height: 38px;
		padding: 3px 0;
	}
	& .widget-chat-media {
		width: 38px;
		height: 38px;
		position: absolute;
		bottom: 3px;
		left: 0px;
		overflow: hidden;
		text-align: center;
		line-height: 38px;

		@include border-radius($border-radius * 10);
		@if $enable-rtl {
			right: 0;
			left: auto;
		}

		& img {
			max-width: 100%;
			max-height: 100%;
			display: block;
		}
	}
	& .widget-chat-info-container {
		background: $white;
		display: inline-block;
		padding: 8px 12px;
		position: relative;
		color: $white;

		@include border-radius($border-radius * 4);
	}
	& .widget-chat-message {
		font-weight: 600;
		color: $dark;

		&:before {
			content: '';
			position: absolute;
			bottom: 0;
			left: -24px;
			border: 0 solid transparent;
			border-top-width: 15px;
			border-right-width: 10px;
			border-right-color: $white;
			border-radius: 50%;
			width: 35px;
			height: 35px;

			@include transform(rotate(10deg));
			@if $enable-rtl {
				left: auto;
				right: -24px;
				border-right-width: 0;
				border-right-color: transparent;
				border-left-width: 10px;
				border-left-color: $white;

				@include transform(rotate(-10deg));
			}
		}
		&:after {
			content: '';
			display: inline-block;
			width: 60px;
			height: 12px;
		}
	}
	& .widget-chat-name {
		font-weight: 600;
	}
	& .widget-chat-time {
		position: absolute;
		right: 12px;
		bottom: 8px;
		font-style: italic;
		color: lighten($dark, 45%);
		font-weight: 600;
		font-size: 10px;

		@if $enable-rtl {
			right: auto;
			left: 12px;
		}
	}
	& .with-media {
		& .widget-chat-info {
			margin-left: 53px;
			min-width: 60%;

			@if $enable-rtl {
				margin-left: 0;
				margin-right: 53px;
			}
		}
		& .right {
			& .widget-chat-info {
				margin-right: 53px;

				@if $enable-rtl {
					margin-right: 0;
					margin-left: 53px;
				}
			}
		}
	}
	& .right {
		& .widget-chat-media {
			left: auto;
			right: 0;

			@if $enable-rtl {
				left: 0;
				right: auto;
			}
		}
		& .widget-chat-info {
			margin-left: 0;
			text-align: right;

			@if $enable-rtl {
				margin-right: 0;
				text-align: left;
			}
		}
		& .widget-chat-time {
			right: auto;
			left: 12px;

			@if $enable-rtl {
				right: 12px;
				left: auto;
			}
		}
		& .widget-chat-message {
			&:after {
				float: left;

				@if $enable-rtl {
					float: right;
				}
			}
			&:before {
				left: auto;
				right: -24px;
				bottom: -5px;
				border-right-width: 0;
				border-right-color: transparent;
				border-left-width: 10px;
				border-left-color: $white;

				@if $enable-rtl {
					left: -24px;
					right: auto;
					border-left-width: 0;
					border-left-color: transparent;
					border-right-width: 10px;
					border-right-color: $white;
				}
			}
		}
	}

	&.widget-chat-rounded {
		@include border-radius($border-radius);

		&.widget-chat-input {
			@include border-radius($border-radius $border-radius 0 0);
		}
	}
}
.inverse-mode {
	&.widget-chat {
		background: $dark;

		& .widget-chat-header {
			border-color: $dark;
		}
		& .widget-chat-header-title {
			color: $white;
		}
		& .widget-chat-header-desc {
			color: lighten($dark, 60%);
		}
		& .widget-chat-body {
			background: $dark-darker;
		}
	}
}

.morris-hover {
	z-index: 0 !important;

	&.morris-default-style {
		padding: 6px 10px;
		color: $dark;
		background: $light;
		border: none;
		font-family: inherit;
		font-size: 12px;
		text-align: center;

		@include border-radius($border-radius * 2);
	}
}
.morris-inverse {
	& .morris-hover {
		background: rgba($dark-darker, .85) !important;
		border: none !important;
		padding: 10px !important;
		color: $white !important;

		@include border-radius($border-radius * 2 !important);
	}
}

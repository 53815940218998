.page-header-fixed {
  padding-top: 50px;

  @include media-breakpoint-down(sm) {
    padding-top: ($header-height-sm * 2) + 1px;
  }

  & .header {
    position: fixed;
  }
}

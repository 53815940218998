
.superbox {
	&.superbox-active {
		font-size: 0;
		margin: -1px -1px 0 0;

		& .superbox-list {
			display: inline-block;
			position: relative;
			background: none;

			& .superbox-img {
				width: 100%;
				cursor: pointer;
				display: block;
				padding-top: 75%;
				position: relative;

				& img {
					display: none;
				}
				& span {
					position: absolute;
					top: 1px;
					left: 1px;
					right: 1px;
					bottom: 1px;
					background-size: cover !important;
					background-position: center !important;
				}
				&:hover {
					opacity: .8;
				}
			}
			&.superbox-O {
				z-index: 10;

				&:before {
					content: '';
					position: absolute;
					left: 50%;
					bottom: 0px;
					border: 10px solid transparent;
					border-bottom-color: $body-bg;
					margin-left: -10px;
					z-index: 10;
				}
				&:after {
					content: '';
					position: absolute;
					left: 50%;
					bottom: -3px;
					border: 10px solid transparent;
					border-bottom-color: $dark;
					margin-left: -10px;
					z-index: 20;
				}
			}
		}
		& .superbox-show {
			background: $dark !important;
			margin: 1px 0;

			@include box-shadow(none);

			& .superbox-current-img {
				max-width: 100%;
				border: none;

				@include box-shadow(0 0 16px rgba($black, .15));
			}
			& .superbox-prev {
				@if $enable-rtl {
					left: auto;
					right: 10px;
				}
				& .icon-circle-arrow-left {
					&:before {
						@if $enable-rtl {
							content: '\e4a2';
						}
					}
				}
			}
			& .superbox-next {
				@if $enable-rtl {
					right: auto;
					left: 10px;
				}
				& .icon-circle-arrow-right {
					&:before {
						@if $enable-rtl {
							content: '\e4a1';
						}
					}
				}
			}
		}
		& .superbox-close {
			cursor: pointer;
			position: absolute;
			top: 15px;
			right: 25px;
			width: 35px;
			height: 35px;

			@if $enable-rtl {
				right: auto;
				left: 25px;
			}
		}
	}
}

.media-messaging {
	& .media {
		padding: 15px;
		 & + .media {
			margin-top: 0;
			padding-top: 15px;
		}
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
		& p {
			margin: 0;
		}
	}
}

body {
	& .bootstrap-colorpalette {
		& .btn-color {
			width: 19px;
			height: 19px;

			@include border-radius($border-radius);

			&:hover,
			&:focus {
				outline: none;
				border: 1px solid rgba($black, .5) !important;
			}
		}
	}
}

.result-list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	width: 100%;

	@include clearfix();

	& > li {
		background: $white;
		overflow: hidden;
		position: relative;

		@include display-flex();
		@include flex-wrap(wrap);
		@include border-radius($border-radius-lg);
		@include clearfix();
		@include media-breakpoint-down(md) {
			padding-top: 160px;
		}
		@include media-breakpoint-down(xs) {
			display: block;
		}

		& + li {
			margin-top: 10px;
		}
		& .result-image {
			width: 240px;
			padding: 0;
			overflow: hidden;
			background: $dark;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;

			@include media-breakpoint-down(lg) {
				width: 180px;
			}
			@include media-breakpoint-down(md) {
				width: auto;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				height: 160px;
			}

			& a {
				display: block;
			}
			& img {
				width: 100%;
			}
			&:hover,
			&:focus {
				opacity: 0.8;
			}
		}
		& .result-info {
			padding: 20px;
			position: relative;

			@include flex(1);
			@include media-breakpoint-down(lg) {
				padding: 15px;
			}

			& .title {
				margin: 0 0 5px;
				font-size: 18px;
				line-height: 22px;

				@include media-breakpoint-down(md) {
					font-size: 16px;
					line-height: 20px;
				}

				& a {
					color: $dark;
				}
			}
			& .location {
				color: lighten($dark, 30%);
				margin-bottom: 15px;
			}
			& .decs {
				margin-bottom: 20px;
				max-height: 32px;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 16px;
			}
			& .btn-row {
				@include clearfix();
				@include display-flex();
				@include flex-wrap(wrap);

				& a {
					color: $dark;
					background: $light;
					font-size: 14px;
					line-height: 18px;
					padding: 8px 10px;

					@include border-radius($border-radius);

					& + a {
						margin-left: 5px;

						@if $enable-rtl {
							margin-left: 0;
							margin-right: 5px;
						}
					}
					&:hover,
					&:focus {
						background: lighten($dark, 65%);
					}
				}
			}
		}
		& .result-price {
			width: 240px;
			font-size: 28px;
			text-align: center;
			background: $light;
			color: $dark;
			padding: 20px;
			position: relative;

			@include display-flex();
			@include flex-direction-column();
			@include flex-justify-content(center);
			@include flex-align(center);
			@include media-breakpoint-down(lg) {
				width: 180px;
				padding: 15px;
			}
			@include media-breakpoint-down(xs) {
				width: auto;
			}

			& small {
				display: block;
				font-size: 11px;
				font-weight: 600;
				color: lighten($dark, 30%);
			}
			& .btn {
				margin-top: 30px;

				@include media-breakpoint-down(xs) {
					margin-top: 15px;
				}
			}
		}
	}
}

.float-sub-menu-container {
	position: fixed;
	width: $sidebar-width;
	margin: 0;
	background: $white;
	z-index: $float-submenu-zindex;

	@include media-breakpoint-down(sm) {
		background: $silver;
	}

	& .float-sub-menu {
		margin: 0;
		list-style-type: none;

		@if $enable-rtl {
			padding: 9px 39px 9px 0;
		} @else {
			padding: 9px 0 9px 39px;
		}

		& li {
			position: relative;

			& > a {
				line-height: 18px;
				padding: 3px 20px 3px 15px;
				display: block;
				text-decoration: none;
				position: relative;
				color: $dark;
				font-weight: 600;

				@if $enable-rtl {
					padding: 3px 15px 3px 20px;
				} @else {
					padding: 3px 20px 3px 15px;
				}

				& .caret {
					display: block;
					width: 20px;
					height: 20px;
					line-height: 20px;
					text-align: center;
					font-size: 11px;
					margin-top: -1px;
					border: none;

					@include fontawesome();
					@if $enable-rtl {
						float: left;
					} @else {
						float: right;
					}

					&:before {
						display: block;
						text-align: center;
						opacity: 0.5;

						@if $enable-rtl {
							content: '\f053';
						} @else {
							content: '\f054';
						}
						@include transition(all 100ms linear);
					}
				}

				&:hover,
				&:focus {
					color: $primary;
				}
				&:after {
					content: '';
					position: absolute;
					background: darken($silver, 5%);
					top: 11px;
					width: 11px;
					height: 2px;

					@if $enable-rtl {
						right: -11px;
					} @else {
						left: -11px;
					}
				}
			}

			&:before {
				content: '';
				background: darken($silver, 5%);
				position: absolute;
				top: 0;
				bottom: 0;
				width: 2px;

				@if $enable-rtl {
					right: -13px;
				} @else {
					left: -13px;
				}
			}
			&:after {
				content: '';
				position: absolute;
				width: 6px;
				height: 6px;
				border: 1px solid darken($silver, 5%);
				top: 11px;
				margin-top: -2px;
				z-index: 10;
				background: darken($silver, 5%);

				@include border-radius($border-radius);
				@if $enable-rtl {
					right: 0;
				} @else {
					left: 0;
				}
			}
			&:first-child {
				&:before {
					top: -14px;
				}
				&:last-child {
					&:before {
						height: 27px;
					}
				}
			}
			&:last-child {
				&:before {
					bottom: auto;
					height: 13px;
				}
			}
			&.has-sub {
				& > a {
					&:before {
						background: $dark;
					}
				}
			}
			&.active {
				& > a {
					color: $primary;

					& .caret {
						&:before {
							opacity: 1.0;

							@if $enable-rtl {
								@include transform(rotate(-90deg));
							} @else {
								@include transform(rotate(90deg));
							}
						}
					}
				}
				&:after {
					border-color: $primary;
					background: $primary;
				}
			}
			&.expanding,
			&.expand {
				& > a {
					color: $dark;

					& .caret {
						&:before {
							opacity: 1.0;

							@if $enable-rtl {
								@include transform(rotate(-90deg));
							} @else {
								@include transform(rotate(90deg));
							}
						}
					}
				}
			}
			&.closed,
			&.closing {
				& > a {
					& .caret {
						&:before {
							@include transform(rotate(0deg));
						}
					}
				}
			}
		}
		& > li {
			&:first-child {
				&:before {
					top: 11px;
				}
				&:last-child {
					&:before {
						height: 0 !important;
					}
				}
			}
		}
		& .active {
			& > .sub-menu {
				display: block;
			}
		}
		& .sub-menu {
			display: none;
			list-style-type: none;
			background: none;

			@if $enable-rtl {
				padding: 0 15px 0 0;
			} @else {
				padding: 0 0 0 15px;
			}
		}
	}
	& .float-sub-menu-arrow {
		position: absolute;
		top: 20px;
		width: 28px;
		height: 2px;
		background: darken($silver, 5%);

		@if $enable-rtl {
			right: 0;
		} @else {
			left: 0;
		}
	}
	& .float-sub-menu-line {
		position: absolute;
		top: 20px;
		width: 2px;
		background: darken($silver, 5%);

		@if $enable-rtl {
			right: 26px;
		} @else {
			left: 26px;
		}
	}
	& .foat-sub-menu-arrow {
		&:before,
		&:after {
			display: none;
		}
	}
}

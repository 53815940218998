body {
	& #gritter-notice-wrapper {
		width: 420px;
		z-index: $header-zindex + 10;

		@include media-breakpoint-down(xs) {
			width: 320px;
		}
		@include media-breakpoint-down(xxs) {
			width: 280px;
		}

		& .gritter-item-wrapper {
			background: none;
			background: rgba($dark-darker, .9);

			@include border-radius($border-radius-lg);

			& .gritter-top,
			& .gritter-bottom {
				background: none;
				display: none;
			}
			& .gritter-item {
				font-family: inherit;
				padding: 12px 95px 12px 15px;
				background: none;

				@include display-flex();
				@include flex(1);
				@include flex-align(center);
				@if $enable-rtl {
					padding-right: 15px;
					padding-left: 95px;
				}
				@include media-breakpoint-down(xs) {
					padding: 10px 70px 10px 10px;

					@if $enable-rtl {
						padding-right: 10px;
						padding-left: 70px;
					}
				}

				& .gritter-with-image,
				& .gritter-without-image {
					float: none;
					width: auto;

					@include flex(1);
				}
				& .gritter-image {
					margin: 0 15px 0 0;
					height: auto;

					@include border-radius($border-radius * 20);
					@if $enable-rtl {
						margin-right: 0;
						margin-left: 15px;
					}
					@include media-breakpoint-down(xs) {
						width: 36px;
						margin-right: 10px;

						@if $enable-rtl {
							margin-right: 0;
							margin-left: 10px;
						}
					}
					@include media-breakpoint-down(xxs) {
						width: 24px;
					}
				}
				& .gritter-title {
					font-size: 14px;
					line-height: 20px;
					padding-bottom: 0;
					font-weight: 600;
					color: $white;
					text-shadow: none;

					@include media-breakpoint-down(xs) {
						font-size: 12px;
						line-height: 18px;
					}
				}
				& p {
					font-size: 11px;
					line-height: 16px;
					letter-spacing: 0.15px;
					color: lighten($dark, 60%);

					@include media-breakpoint-down(xs) {
						font-size: 10px;
						line-height: 14px;
						letter-spacing: 0;
					}
				}
				& .gritter-close {
					left: auto;
					right: 0;
					top: 0;
					bottom: 0;
					background: none;
					width: 80px;
					height: auto;
					display: block !important;
					font-family: inherit;
					border-left: 1px solid rgba($white, .1);
					color: lighten($dark, 45%);

					@if $enable-rtl {
						right: auto;
						left: 0;
						border-left: none;
						border-right: 1px solid rgba($white, .1);
						text-align: right;
					}
					@include media-breakpoint-down(xs) {
						width: 60px;
					}

					&:before {
						content: 'Close';
						position: absolute;
						text-align: center;
						top: 50%;
						right: 0;
						left: 0;
						color: inherit;
						z-index: 10;
						text-indent: 0;
						font-family: inherit;
						font-size: 12px;
						font-weight: 600;
						line-height: 12px;
						margin-top: -6px;
					}
					&:hover,
					&:focus {
						color: $white;
						outline: none;
					}
				}
			}

			&.gritter-light {
				& .gritter-item,
				& .gritter-bottom,
				& .gritter-top {
					background: rgba($white, .95);
					color: lighten($dark, 30%);
				}
				& .gritter-item {
					& .gritter-title {
						color: $dark;
						font-weight: 600;
					}
					& p {
						color: lighten($dark, 15%);
					}
				}
				& .gritter-close {
					border-color: $border-color;
					color: lighten($dark, 30%);

					&:hover,
					&:focus {
						&:before {
							color: $dark;
						}
					}
				}
			}
			&.without-rounded-img {
				& .gritter-item {
					& .gritter-image {
						@include border-radius(0);
					}
				}
			}
		}
	}
}

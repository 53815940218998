/*
   0.0 Default Sizes
   1.0 Color system
   2.0 RTL Setting
   3.0 Component Color Setting
   4.0 Grid & Container Setting
   5.0 Body Setting
   6.0 App Component Setting
   7.0 z-index Setting
   8.0 Font Setting
   9.0 Radius Setting
  10.0 Caret Setting
  11.0 Heading / Paragraph Setting
  12.0 Pagination Setting
  13.0 Button Setting
  14.0 Form Component Setting
  15.0 Input Setting
  16.0 Table Setting
  17.0 Modal Setting
  18.0 List Group Setting
  19.0 Tabs Setting
  20.0 Alert Setting
  21.0 Card Setting
  22.0 Dropown Setting
  23.0 Progress Setting
*/

// 0.0 Default Sizes
$size-base: 8px;
$size-sm: $size-base * 0.75;
$size-lg: $size-base * 1.25;


// 1.0 Color system
$primary: #7f2ec2 !default;
$primary-color: #7f2ec2 !default;
$secondary: #d54a26 !default;
$secondary-color: #d54a26 !default;
$black: #000000 !default;
$blue: #000000 !default;
$cyan: #D54A26 !default;
$dark: #1f2225 !default;
$dark-darker: #000000 !default;
$green: #A6D59A !default;
$indigo: #7b2cff !default;
$lime: #75c713 !default;
$light: #f2f3f4 !default;
$orange: #FBBA37 !default;
$purple: #5058e6 !default;
$pink: #ff107e !default;
$red: #DB575B !default;
$silver: #eeeeee !default;
$teal: #D54A26 !default;
$white: #FFFFFF !default;
$yellow: #ffd900 !default;
$flame: #D64B26 !default;
$inverse: $dark !default;
$link-color: $blue !default;
$success: $green !default;
$warning: $orange !default;
$colors: (
  'black': $black,
  'cyan': $cyan,
  'dark': $dark,
  'dark-darker': $dark-darker,
  'green': $green,
  'indigo': $indigo,
  'lime': $lime,
  'light': $light,
  'orange': $orange,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'silver': $silver,
  'teal': $teal,
  'white': $white,
  'yellow': $yellow,
  'muted': darken($silver, 30%),
  'aqua': $cyan,
  'flame': $flame,
);
$theme-colors: (
  'dark': $dark,
  'inverse': $dark,
  'black': $black,
  'white': $white,
  'grey': $silver,
  'muted': $silver,
  'silver': $silver,
  'lime': $lime,
  'aqua': $cyan
);

@if $primary-color =='black' {
  $primary: lighten($dark, 20%);
}

// 2.0 RTL Setting

$enable-rtl: false !default;


// 3.0 Component Color Setting

$component-active-bg: $blue !default;
$component-active-color: $white !default;
$custom-control-indicator-checked-disabled-bg: rgba($blue, .5) !default;


// 4.0 Grid & Container Setting

$grid-breakpoints: (
  xxs: 0px,
  xs: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1660px,
  xxxl: 1900px
);
$container-max-widths: (
  sm: 540px,
  md: 750px,
  lg: 960px,
  xl: 1170px) !default;
$spacer: 1rem !default;
$grid-gutter-width: 2rem !default;


// 5.0 Body Setting

$body-bg: #e8ebef !default;
$body-color: $dark !default;
$body-letter-spacing: 0 !default;
$border-color: lighten($dark, 75%
);


// 6.0 App Component Setting

$boxed-layout-width: 1440px !default;
$content-padding-x: 18px !default;
$content-padding-y: 12px !default;
$content-padding-x-sm: 12px !default;
$content-padding-y-sm: 8px !default;
$header-height: 50px !default;
$header-height-sm: 50px !default;
$sidebar-width: 240px !default;
$sidebar-minified-width: 60px !default;
$sidebar-wide-width: 250px !default;
$top-menu-height: 40px !default;


// 7.0 z-index Setting

$btn-scroll-to-top-zindex: 1020 !default;
$content-zindex: 1020 !default;
$float-submenu-zindex: 1020 !default;
$footer-zindex: 1020 !default;
$header-zindex: 1020 !default;
$header-fixed-zindex: 1040 !default;
$sidebar-zindex: 1010 !default;
$top-menu-zindex: 1015 !default;
$theme-panel-zindex: 1020 !default;
$page-loader-zindex : 9999 !default;
$page-title-zindex: 1020 !default;
$pace-loader-zindex: 1030 !default;
$panel-expand-zindex: 1080 !default;


// 8.0 Font Setting

$font-size-base: 12 !default;
$font-size-sm: 11 !default;
$font-size-lg: 13 !default;
$line-height-base: 1.5 !default;
$line-height-sm: 1.3 !default;
$line-height-lg: 1.7 !default;


// 9.0 Radius Setting

$border-radius: 6px !default;
$border-radius-lg: 8px !default;
$border-radius-sm: 4px !default;


// 10.0 Caret Setting

$enable-caret: false !default;


// 11.0 Heading / Paragraph Setting

$headings-font-weight: 600 !default;
$paragraph-margin-bottom: 15px !default;


// 12.0 Pagination Setting

$pagination-border-width: 1px !default;
$pagination-color: lighten($dark, 30%) !default;
$pagination-hover-color: $dark !default;
$pagination-hover-border-color: $border-color !default;
$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $component-active-bg !default;
$pagination-focus-box-shadow: 0 !default;


// 13.0 Button Setting

$btn-focus-width: 0 !default;
$btn-box-shadow: 0 !default;
$btn-border-width: 1px !default;


// 14.0 Form Component Setting

$form-component-active-bg: $blue;
$form-component-active-color: $blue;
$form-feedback-font-size: 11px !default;
$form-feedback-tooltip-font-size: 11px !default;


// 15.0 Input Setting

$input-border-width: 1px !default;
$input-focus-border-color: #5db0ff !default;
$input-btn-padding-y: 7px !default;
$input-btn-padding-y-sm: 0.3125rem !default;
$input-btn-padding-x-sm: 0.625rem !default;
$input-btn-font-size-sm: 0.75rem !default;
$input-btn-line-height-sm: $line-height-sm !default;
$input-color: $dark !default;
$input-border-color: $border-color !default;
$input-group-addon-bg: $border-color !default;
$input-group-addon-border-color: $border-color !default;


// 16.0 Table Setting

$table-cell-padding: 8px !default;
$table-cell-padding-sm: 5px !default;
$table-accent-bg: lighten($dark, 82%) !default;
$table-hover-bg: lighten($dark, 82%) !default;
$table-border-color: lighten($dark, 72%) !default;
$table-component-active-bg: $blue !default;
$table-component-active-color: $blue !default;


// 17.0 Modal Setting

$modal-header-padding-y: 15px !default;
$modal-header-padding-x: 15px !default;


// 18.0 List Group Setting

$list-group-border-color: rgba($dark, .125) !default;
$list-group-item-padding-y: 10px !default;
$list-group-item-padding-x: 15px !default;


// 19.0 Tabs Setting

$nav-tabs-border-width: 0 !default;
$nav-tabs-link-active-bg: $white !default;


// 20.0 Alert Setting

$alert-padding-y: .9375rem !default; // 15px
$alert-padding-x: .9375rem !default; // 15px
$alert-margin-bottom: 1.25rem !default; // 20px
$alert-border-width: 0 !default;


// 21.0 Card Setting

$card-spacer-y: 15px !default;
$card-spacer-x: 15px !default;
$card-img-overlay-padding: 15px !default;
$card-columns-gap: 15px !default;
$card-border-color: rgba($dark, .15) !default;
$card-cap-bg: lighten($dark, 75%) !default;


// 22.0 Dropown Setting

$dropdown-border-width: 0 !default;
$dropdown-font-size: $font-size-sm !default;


// 23.0 Progress Setting

$progress-bar-bg: $blue !default;
$progress-bg: lighten($dark, 70%) !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: none !default;
$progress-bar-color: $white !default;

.widget-chart {
	position: relative;
	overflow: hidden;
	margin-bottom: 20px;
	background: $white;

	@include border-radius($border-radius);
	@include media-breakpoint-up(sm) {
		@include display-flex();
	}

	& .chart-title {
		font-size: 16px !important;
		line-height: 20px;
		color: $dark;
		font-weight: 600 !important;
		margin: 2px 5px 5px;

		& small {
			display: block;
			margin-top: 3px;
			font-size: 12px;
			line-height: 16px;
			color: $dark;
			font-weight: normal;
		}
	}
	& .widget-chart-content {
		padding: 15px;

		@include flex(1);

		& + .widget-chart-sidebar {
			@include media-breakpoint-up(md) {
				@include border-radius(0 $border-radius $border-radius 0);
			}
			@include media-breakpoint-down(xs) {
				@include border-radius(0 0 $border-radius $border-radius);
			}
		}
	}
	& .widget-chart-full-width {
		margin: 0 -15px;
	}
	& .widget-chart-sidebar {
		padding: 15px 15px;
		position: relative;

		@include display-flex();
		@include flex-direction-column();
		@include media-breakpoint-up(md) {
			width: 230px;
		}
		@include media-breakpoint-only(sm) {
			width: 200px;
		}

		& .chart-number {
			font-size: 24px;
			font-weight: 600;
			line-height: 28px;
			color: $dark;

			& small {
				font-size: 12px;
				line-height: 16px;
				margin-top: 3px;
				color: $dark;
				display: block;
				font-weight: normal;
			}
		}
		& .chart-legend {
			list-style-type: none;
			margin: 0;
			padding: 0;
			font-size: 12px;
			font-weight: 700;

			& > li {
				color: $dark;

				& span {
					color: lighten($dark, 15%);
					margin-left: 5px;
					font-weight: 600;
				}
				& + li {
					margin-top: 5px;
				}
			}
		}
	}
}
.widget-chart-info {
	color: $dark;

	& .widget-chart-info-title {
		margin: 0 0 5px;
		font-size: 14px;
	}
	& .widget-chart-info-desc {
		font-size: 11px;
		color: lighten($dark, 15%);
		margin-bottom: 20px;
	}
	& .widget-chart-info-progress {
		font-size: 11px;
		margin-bottom: 5px;

		& .progress {
			margin-bottom: 0;
		}
	}
}
.widget-chart-content {
	padding: 10px;
	background: $light;
}
.inverse-mode {
	& .widget-chart-content {
		background: darken($dark, 7.5%);
	}
	& .widget-chart-info {
		color: lighten($dark, 60%);

		& .widget-chart-info-desc {
			color: lighten($dark, 45%);
		}
	}
	& .nvd3 {
		text {
			fill: rgba($white, .75);
		}
		& .nv-axis {
			& line,
			& path {
				stroke: rgba($white, .15);
			}
		}
	}
}

.dropdown-menu {
	font-size: 12px;

	@include box-shadow($dropdown-box-shadow);
	@if $enable-rtl {
		text-align: right;
	}
}
.dropdown-item {
	padding: 5px 15px;
	font-weight: 600;
	color: lighten($dark, 15%);
	text-decoration: none;

	&:hover,
	&:focus {
		color: $dark;
		background: lighten($silver, 2%);
	}
}

.media {
	@include display-flex();
	@include flex(1);

	& + .media {
		margin-top: 15px;
	}
	& .media-heading {
		margin-top: 0;
		margin-bottom: 5px;
	}
	& .media-object {
		width: 128px;
	}
	&.media-lg .media-object {
		width: 256px;
	}
	&.media-sm .media-object {
		width: 64px;
	}
	&.media-xs .media-object {
		width: 32px;
	}
	& > .pull-left,
	& > .media-left {
		padding-right: 15px;

		@if $enable-rtl {
			padding-right: 0;
			padding-left: 15px;
		}
	}
	& > .pull-right,
	& > .media-right {
		padding-left: 15px;

		@if $enable-rtl {
			padding-left: 0;
			padding-right: 15px;
		}
	}
}

.jvectormap-container {
	& .jvectormap-zoomin,
	& .jvectormap-zoomout {
		width: 16px;
		height: 16px;
		padding: 0;
		line-height: 16px;
		left: 15px;
		color: $dark;
		background: $white;

		@include box-shadow(0 2px 8px rgba($black, .25));
		@if $enable-rtl {
			left: auto;
			right: 15px;
		}
	}
	& .jvectormap-label {
		padding: 3px 6px;
		border: none;
		background: rgba($black, .6);
	}
}
.jvectormap-tip {
	padding: 3px 6px !important;
	border: none !important;
	background: rgba($black, .6) !important;
}
.map {
	& .jvectormap-zoomin,
	& .jvectormap-zoomout {
		width: 20px !important;
		height: 20px !important;
		font-size: 14px;
		line-height: 20px !important;
		left: $content-padding-x !important;

		@if $enable-rtl {
			left: auto !important;
			right: $content-padding-x !important;
		}
		@include media-breakpoint-down(sm) {
			left: $content-padding-x-sm !important;

			@if $enable-rtl {
				left: auto !important;
				right: $content-padding-x-sm !important;
			}
		}
	}
	& .jvectormap-zoomin {
		top: $header-height + 20px;

		@include media-breakpoint-down(lg) {
			top: $header-height + 20px + 30px;
		}
	}
	& .jvectormap-zoomout {
		top: $header-height + 45px;

		@include media-breakpoint-down(lg) {
			top: $header-height + 20px + 55px;
		}
	}
}

.map {
	position: absolute;
	top: $header-height;
	bottom: 0;
	left: $sidebar-width;
	right: 0;
	z-index: 0;
	overflow: hidden;

	@include transform(translateZ(0));
	@if $enable-rtl {
		left: 0;
		right: $sidebar-width;
	}
	@include media-breakpoint-down(sm) {
		left: 0;
		top: $header-height-sm * 2 + 1px;

		@if $enable-rtl {
			right: 0;
		}
	}

	& .map-float-table {
		position: absolute;
		right: 25px;
		bottom: 25px;
		background: rgba($dark-darker, .8);

		@if $enable-rtl {
			right: auto;
			left: 25px;
		}
	}
}
.map-content {
	padding: 0 $content-padding-x;
	z-index: 10;
	position: relative;
}

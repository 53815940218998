.DivSpans {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;

  .sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    padding: 5px;
    margin: 5px;
    width: 100%;

    p {
      color: black;
    }

    .field-title {
      font-weight: bold;
    }

    .field-body {
      font-weight: lighter;
    }
  }
}

.card {
  width: 250px;

  @media (max-width: 1200px) {
    width: 200px;
  }

  @media (max-width: 1050px) {
    width: 150px;
  }

  @media (max-width: 890px) {
    width: 100px;
  }

  @media (max-width: 750px) {
    width: 50px;
  }
}

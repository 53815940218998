.slimScrollDiv {
	& .slimScrollBar,
	& .slimScrollRail {
		width: 6px !important;
		right: 2px !important;

		@if $enable-rtl {
			right: auto !important;
			left: 2px !important;
		}
	}
	& .slimScrollBar {
		background: none !important;

		&:before {
			content: '';
			position: absolute;
			top: 2px;
			right: 0;
			bottom: 2px;
			left: 0;
			background: $dark !important;

			@include border-radius($border-radius * 2);
		}
	}
}

.inverse-mode {
	& .slimScrollDiv {
		& .slimScrollBar {
			&:before {
				background: rgba($white, .5) !important;
			}
		}
	}
}

.note-editor {
	& .note-toolbar {
		background: $dark;
		padding: 10px 15px !important;

		@include border-radius(0);

		&.panel-heading {
			& .note-btn-group {
				margin-top: 0 !important;

				& .btn {
					margin: 0 !important;

					&.dropdown-toggle {
						@if $enable-rtl {
							width: auto;
							padding-left: 5px;
							padding-right: 5px;
						}
					}
				}
			}
		}
		& .dropdown-menu {
			& > li {
				& > a {
					display: block;
					padding: 5px 15px;
					color: $dark;
					text-decoration: none;
					white-space: nowrap;
				}
			}
		}
	}
	& .note-placeholder {
		padding: 15px !important;
	}
	& .note-editing-area {
		& .note-editable {
			padding: 15px !important;
		}
	}
	& .modal {
		& .modal-header {
			display: block;

			& .close {
				margin-top: -10px;

				@if $enable-rtl {
					float: left;
				}
			}
			& .modal-title {
				@if $enable-rtl {
					float: right;
				}
			}
		}
	}

	&.note-frame {
		border: none !important;
		margin: 0 !important;
		background: none;
	}
	&.fullscreen {
		& .note-toolbar-wrapper {
			display: none;
		}
	}
}
.note-popover {
	&.popover {
		display: none;

		& .popover-content,
		& .note-toolbar {
			padding: 10px 15px !important;
			background: $dark;

			@include border-radius(0);
		}
	}
}
.note-toolbar .dropdown-toggle:after {
	display: none !important;
}

.media-list {
	padding-left: 0;
	padding-right: 0;
	list-style: none;

	&.media-list-with-divider {
		& .media {
			& + .media {
				border-top: 1px solid $border-color;
				padding-top: 15px;
			}
		}
	}
}

.dropdown-menu {
	&.media-list {
		max-width: 360px;
		padding: 0;

		& .media {
			border-top: 1px solid lighten($border-color, 5%);
			border-bottom: 1px solid lighten($border-color, 5%);
			margin-top: 0;
			margin-bottom: -1px;

			& .media-object {
				height: 48px;
				width: 48px;
				line-height: 48px;
				font-size: 18px;
				color: $white;
				text-align: center;

				@include border-radius($border-radius-lg);
			}
			& .media-object-icon {
				position: absolute;
				margin-left: 30px;
				border: 2px solid $white;
				background: $white;
				margin-top: -11px;
				display: block;
				width: 22px;
				height: 22px;
				text-align: center;
				font-size: 18px;
				line-height: 18px;

				@include border-radius($border-radius * 10);
				@if $enable-rtl {
					margin-right: 30px;
					margin-left: 0;
				}
			}
			& .media-left {
				padding-right: 12px;

				@if $enable-rtl {
					padding-right: 0;
					padding-left: 12px;
				}
			}
			& .media-right {
				padding-left: 12px;

				@if $enable-rtl {
					padding-left: 0;
					padding-right: 12px;
				}
			}
			& .media-heading {
				color: $dark;
				margin-bottom: 0;
				font-size: 12.5px;
				line-height: 18px;
			}
			& p {
				text-overflow: ellipsis;
				overflow: hidden;
				margin-bottom: 5px;
				line-height: 14px;
				max-width: 270px;
				color: lighten($dark, 15%);
				font-size: 11px;
			}
		}
		& .dropdown-header {
			font-weight: bold;
			color: $dark;
			font-size: 10px;
			background: $white;
			padding: 10px 15px;
		}
		& .dropdown-item {
			padding: 10px 15px;
		}
		& .dropdown-footer {
			padding: 10px 15px;
		}
	}
}

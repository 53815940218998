@import 'variables';
@import 'theme';
@import 'mixins';
@import 'custom';
@import 'helper';
@import 'layout';
@import 'plugins';
@import 'reboot';
@import 'ui';
@import 'react';
@import 'app';

a {
  text-decoration: none;
}

a:hover{
  text-decoration: none;
  color: #333;
}



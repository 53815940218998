.has-success,
.is-valid {
	&.radio,
	& .radio {
		&.radio-css {
			& label {
				color: darken($success, 5%);

				&:before {
					background: rgba($success, .5);
				}
			}
		}
	}
}
.has-error,
.is-invalid {
	&.radio,
	& .radio {
		&.radio-css {
			& label {
				color: darken($danger, 5%);

				&:before {
					background: rgba($danger, .5);
				}
			}
		}
	}
}

.radio {
	&.radio-css {
		position: relative;
		line-height: 16px;
		padding-top: 7px;

		& label {
			padding-left: 24px;
			margin: 0;
			position: relative;

			@if $enable-rtl {
				padding-left: 0;
				padding-right: 24px;
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 16px;
				height: 16px;
				background: lighten($dark, 65%);

				@include border-radius(16px);
				@if $enable-rtl {
					left: auto;
					right: 0;
				}
			}
		}
		& input {
			display: none;

			&:checked {
				& + label {
					&:before {
						background: $form-component-active-bg;
						border-color: $form-component-active-bg;
					}
					&:after {
						content: '';
						position: absolute;
						top: 5px;
						left: 5px;
						width: 6px;
						height: 6px;
						background: $white;

						@include border-radius(6px);
						@if $enable-rtl {
							left: auto;
							right: 5px;
						}
					}
				}
			}
		}

		&.radio-inline {
			padding-left: 0;
			display: inline-block;

			& + .radio-inline {
				margin-left: 15px;

				@if $enable-rtl {
					margin-left: 0;
					margin-right: 15px;
				}
			}
		}
		&.radio-success input:checked + label:before {
			background: $success;
			border-color: $success;
		}
		&.radio-lime input:checked + label:before {
			background: $lime;
			border-color: $lime;
		}
		&.radio-indigo input:checked + label:before {
			background: $indigo;
			border-color: $indigo;
		}
		&.radio-purple input:checked + label:before {
			background: $purple;
			border-color: $purple;
		}
		&.radio-inverse input:checked + label:before {
			background: $inverse;
			border-color: $inverse;
		}
		&.radio-warning input:checked + label:before {
			background: $warning;
			border-color: $warning;
		}
		&.radio-danger input:checked + label:before {
			background: $danger;
			border-color: $danger;
		}
		&.radio-info input:checked + label:before {
			background: $info;
			border-color: $info;
		}
		&.radio-pink input:checked + label:before {
			background: $pink;
			border-color: $pink;
		}
		&.radio-yellow input:checked + label:before {
			background: $yellow;
			border-color: $yellow;
		}
	}
}

div {
	& .bootstrap-select {
		& .btn {
			&:focus,
			&:active {
				outline: none !important;
			}
			&.dropdown-toggle {
				&:after {
					@if $enable-rtl {

					} @else {
						content: '';
						border: 5px solid transparent;
						border-top-color: rgba($white, .5);
						position: relative;
						top: 3px;
					}
				}
				&:before {
					@if $enable-rtl {
						content: '';
						border: 5px solid transparent;
						border-top-color: rgba($white, .5);
						position: relative;
						top: 3px;
						z-index: 10;
					}
				}
			}
			&.btn-light,
			&.btn-white {
				color: lighten($dark, 35%) !important;

				&.dropdown-toggle {
					&:after {
						border-top-color: rgba($dark, .5);
					}
				}
			}
			&.bs-placeholder {
				color: rgba($white, .75) !important;

				&:active,
				&:hover,
				&:focus {
					color: rgba($white, .75) !important;
				}
			}
		}
		& .dropdown-menu {
			& .bs-searchbox {
				padding: 0 8px 8px;
			}
		}
	}
}

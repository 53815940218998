.widget-stats,
.widget.widget-stats {
	position: relative;
	color: $dark;
	padding: 20px;

	@include border-radius($border-radius);

	& .stats-info {
		& h4 {
			font-size: 12px;
			margin: 5px 0;
			color: rgba($white, .7);
		}
		& p {
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 0;
			color: $white;
		}
	}
	& .stats-link {
		& a {
			display: block;
			margin: 20px -20px -20px;
			padding: 7px 15px;
			background: rgba($black, .4);
			text-align: right;
			color: rgba($white, .7);
			text-decoration: none;

			@if $enable-rtl {
				text-align: left;
			}

			&:hover,
			&:focus {
				background: rgba($black, .6);
				color: $white;
			}
		}
	}
	& .stats-icon {
		font-size: 42px;
		height: 56px;
		width: 56px;
		text-align: center;
		line-height: 56px;
		color: rgba($black, .15);
		position: absolute;
		right: 15px;
		top: 15px;
		margin-left: 15px;

		@if $enable-rtl {
			right: auto;
			left: 15px;
		}

		&.stats-icon-lg {
			font-size: 128px;
			top: 15px;
			right: 0;
			color: $white;
			width: 128px;
			height: 50px;
			line-height: 50px;
			text-shadow: 3px 7px rgba($black, .25);
			opacity: .15;

			@if $enable-rtl {
				right: auto;
				left: 0;
			}

			& i {
				position: relative;
			}
		}
		&.stats-icon-square {
			opacity: 1;
			font-size: 42px;
			top: 15px;
			right: 15px;
			height: 56px;
			width: 56px;
			text-align: center;
			line-height: 56px;
			margin-left: 15px;

			@include border-radius($border-radius * 4);
			@if $enable-rtl {
				right: auto;
				left: 15px;
			}
		}
	}
	& .stats-content {
		position: relative;
	}
	& .stats-title {
		position: relative;
		margin: 0 0 15px;
		font-size: 14px;
		font-weight: 600;
		line-height: 18px;
		color: $dark;
	}
	& .stats-desc {
		margin: -5px 0 -3px;
		color: rgba($dark, .7);
		font-weight: 600;
	}
	& .stats-number {
		font-size: 24px;
		margin-bottom: 15px;
		font-weight: 700;

		& .increase {
			font-size: 16px;
			color: $green;
		}
		& .decrease {
			font-size: 16px;
			color: $red;
		}
	}
	& .stats-progress {
		background: $body-bg;
		height: 4px;
		margin: 0 0 15px;

		@include border-radius($border-radius);

		& .progress-bar {
			background: $primary;

			@include border-radius(0 $border-radius $border-radius 0);
		}

		&.stats-progress-light {
			background: rgba($white, .3);
		}
	}

	&.text-inverse {
		&.stats-desc {
			letter-spacing: 0;
			font-weight: 400;
			color: rgba($black, .85);
		}
		&.stats-title {
			font-weight: 700;
			color: rgba($black, .85);
		}
		&.stats-number {
			letter-spacing: 0;
			font-weight: 400;
		}
	}
	&.bg-white {
		& .stats-progress {
			background: rgba($black, .1);

			& .progress-bar {
				background: $dark;
			}
		}
	}
}

.widget-icon {
	position: relative;
	overflow: hidden;
	width: 40px;
	height: 40px;
	font-size: 28px;

	@include display-flex();
	@include flex-align(center);
	@include flex-justify-content(center);

	&.user {
		font-size: 36px;

		& i {
			display: block;
			margin: 0 auto;
			padding-top: 38%;
		}
	}
	&.widget-icon-xs {
		width: 20px;
		height: 20px;
		font-size: 14px;

		&.user {
			font-size: 18px;
		}
	}
	&.widget-icon-sm {
		width: 30px;
		height: 30px;
		font-size: 21px;

		&.user {
			font-size: 27px;
		}
	}
	&.widget-icon-lg {
		width: 60px;
		height: 60px;
		font-size: 42px;

		&.user {
			font-size: 54px;
		}
	}
	&.widget-icon-xl {
		width: 80px;
		height: 80px;
		font-size: 56px;

		&.user {
			font-size: 72px;
		}
	}
}

.page-content-full-height {
  height: 100%;

  & .content {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      @include transform(translateZ(0));
    }
  }
}

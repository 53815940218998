.progress {
	& .progress-bar {
		font-weight: 600;
	}
	&.progress-xs {
		height: 5px;

		& .progress-bar {
			line-height: 5px;
		}
	}
	&.progress-sm {
		height: 10px;

		& .progress-bar {
			line-height: 10px;
		}
	}
	&.progress-lg {
		height: 30px;

		& .progress-bar {
			line-height: 30px;
		}
	}
}

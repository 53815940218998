
.editable-container {
	@include display-flex();
	@include flex(1);

	& .editableform {
		display: block;

		& .form-group {
			& > div {
				margin-bottom: -5px;

				@include display-flex();
				@include flex(1);
				@include flex-flow(row wrap);

				& .editable-input {
					margin-right: 5px;
					margin-bottom: 5px;

					@if $enable-rtl {
						margin-right: 0;
						margin-left: 5px;
					}

					& .form-control {
						@if $enable-rtl {
							padding-right: 12px !important;
							padding-left: 24px;
						}
					}
					& .editable-clear-x {
						right: 12px;

						@if $enable-rtl {
							right: auto;
							left: 12px;
						}
					}
					& .editable-checklist {
						& > div {
							& > label {
								@include flex-justify-content(left);

								& input {
									margin-right: 5px;
									height: inherit;

									@if $enable-rtl {
										margin-right: 0;
										margin-left: 5px;
									}
								}
							}
						}
					}
					& .input-append {
						&.date {
							position: relative;

							& .form-control {
								margin-top: -1px;
								@include display-inline-flex();
								@if $enable-rtl {
									@include border-radius(0 $border-radius $border-radius 0);
								} @else {
									@include border-radius($border-radius 0 0 $border-radius);
								}
							}
							& .add-on {
								background: $border-color;
								padding: 5px 10px;
								min-height: 34px;

								@include display-inline-flex();
								@include flex-align(center);
								@if $enable-rtl {
									@include border-radius($border-radius 0 0 $border-radius);
								} @else {
									@include border-radius(0 $border-radius $border-radius 0);
								}

								& i {
									width: auto;
									height: auto;
									margin: 0;

									@include fontawesome();

									&:before {
										content: '\f133';
									}
								}
							}
							& .datetimepicker {
								width: 300px;
								color: $dark;
								@include border-radius($border-radius * 2);

								& table {
									width: 100%;

									& tfoot {
										& .today {
											&:hover,
											&:focus {
												background: lighten($dark, 75%);
											}
										}
									}
								}
								& .datetimepicker-hours,
								& .datetimepicker-days,
								& .datetimepicker-months,
								& .datetimepicker-years,
								& .datetimepicker-decades,
								& .datetimepicker-centuries {
									& table {
										& thead {
											& tr {
												& th {
													border: none;

													&.prev,
													&.next,
													&.switch {
														line-height: 20px;
														padding: 5px;

														@include border-radius($border-radius);

														&:hover,
														&:focus {
															background: lighten($dark, 75%);

															&:before {
																color: $dark;
															}
														}
													}
													&.prev,
													&.next {
														color: transparent;
														width: 14.28%;

														@include fontawesome();

														&:before {
															color: lighten($dark, 30%);
															position: absolute;
															font-size: 14px;
														}
													}
													&.prev {
														&:before {
															content: '\f104';
														}
													}
													&.next {
														&:before {
															content: '\f105';
														}
													}
													&.switch {
														width: auto;
														font-weight: bold;
													}
													&.dow {
														padding: 5px 0;
														border: none;
														font-weight: bold;
													}
												}
											}
										}
										& tbody {
											& tr {
												& td {
													padding: 5px;

													& span {
														&.hour,
														&.decade,
														&.year,
														&.month,
														&.century {
															font-weight: 600;
															color: lighten($dark, 30%);
															text-shadow: none;

															@include border-radius($border-radius);

															&:hover,
															&:focus {
																background: lighten($dark, 75%);
															}
															&.focused {
																background: lighten($dark, 70%);
																color: $dark;
															}
															&.active {
																background: $form-component-active-bg !important;
																color: $white;
															}
														}
													}
													&.day {
														font-weight: 600;
														color: lighten($dark, 15%);
														padding: 5px;

														@include border-radius($border-radius);

														&:hover,
														&:focus {
															background: lighten($dark, 75%);
														}
														&.today {
															background: lighten($dark, 70%) !important;
															color: $dark;
														}
														&.active {
															background: $form-component-active-bg !important;
															color: $white;
															text-shadow: none !important;
														}
														&.old {
															color: lighten($dark, 60%);
														}
														&.new {
															color: lighten($dark, 45%);
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
					& .editable-address {
						& input {
							border: 1px solid $input-border-color;
							padding: 6px 10px;

							@include border-radius($border-radius);

							&:focus {
								outline: none;
								border-color: $input-focus-border-color;

								@include transition(all .2s linear);
								@include box-shadow($input-focus-box-shadow);
							}
						}
					}
					& .twitter-typeahead {
						& .tt-hint {
							line-height: 22px;
							padding: 5px 11px;
							opacity: 0.6;
						}
						& .tt-dropdown-menu {
							border: none;

							@include box-shadow($dropdown-box-shadow);
							& .tt-suggestion {
								padding: 5px 12px;

								&.tt-is-under-cursor {
									background: $form-component-active-bg;
									color: $white;
								}
							}
						}
					}
				}
				& .editable-buttons {
					margin-left: 0;
					margin-bottom: 5px;

					@include display-flex();

					& .btn {
						width: 34px;
						height: 34px;

						@include display-flex();
						@include flex-align(center);
						@include flex-justify-content(center);

						& + .btn {
							margin-left: 5px;

							@if $enable-rtl {
								margin-left: 0;
								margin-right: 5px;
							}
						}
						& .glyphicon-ok {
							@include fontawesome();

							&:before {
								content: '\f00c';
							}
						}
						& .glyphicon-remove {
							@include fontawesome();

							&:before {
								content: '\f00d';
							}
						}
					}
					&.editable-buttons-bottom {
						display: block;

						& .btn {
							@include display-inline-flex();
						}
					}
				}
			}
			&.has-error {
				& .form-control {
					border-color: mix($black, $danger, 7.5%);
					background: mix($white, $danger, 90%);
				}
				& .editable-error-block {
					margin: 0 0 0 10px;
					white-space: nowrap;
					color: mix($black, $danger, 15%);

					@if $enable-rtl {
						margin-left: 0;
						margin-right: 10px;
					}
				}
			}
		}
	}
}

.fc {
	@if $enable-rtl {
		direction: rtl;
		text-align: right;
	}

	& .fc-toolbar {
		&.fc-header-toolbar {
			margin-bottom: 0;

			@include clearfix();

			& h2 {
				font-size: 20px;
				line-height: 30px;
				margin-bottom: 10px;
			}

			& .fc-button-group {
				margin-bottom: 10px;

				& .fc-button {
					text-shadow: none;
					background: $white;
					text-transform: capitalize;
					font-weight: 600;
					border: none;
					padding: 6px 12px;
					height: auto;
					color: lighten($dark, 45%);
					margin: 0;

					@include box-shadow(none);
					@include transition(all .1s linear);

					& + .fc-button {
						margin-left: 1px;
					}

					& .fc-icon {
						margin: 0 -3px;
						top: 0;

						& .fc-icon-left-single-arrow, {
							@include fontawesome();

							&:after {
								content: '\f0d9';
							}
						}
						& .fc-icon-right-single-arrow {
							@include fontawesome();

							&:after {
								content: '\f0da';
							}
						}
					}

					&.fc-state-disabled {
						opacity: 1;
						color: lighten($dark, 45%);
						cursor: not-allowed;
					}
					&.fc-state-active {
						background: lighten($dark, 75%);
						color: $dark;
					}
					&:hover,
					&:focus {
						color: $dark;
						outline: none;
					}

					&.fc-prev-button,
					&.fc-next-button {
						color: $dark;

						&:hover,
						&:focus {
							color: $dark;
						}
					}
					&.fc-today-button {
						color: $dark;

						&.fc-state-disabled {
							color: lighten($dark, 45%);
							cursor: not-allowed;
						}
					}
				}
			}
		}
	}
	& .fc-view-container {
		background: $white;

		& .fc-head {
			& .fc-head-container {
				border: none;

				& .fc-widget-header {
					border-bottom: 1px solid lighten($border-color, 5%);

					& th {
						background: lighten($dark, 75%);
						font-weight: 600;
						color: $dark;
						padding: 2px 15px;
						border-color: lighten($border-color, 5%);

						&.fc-axis {
							padding-left: 4px;
							padding-right: 4px;
						}
					}
				}
			}
		}
		& .fc-body {
			& > tr {
				& > td {
					&.fc-widget-content {
						border: none;

						& .fc-divider {
							background: lighten($dark, 75%);
							border-color: lighten($border-color, 5%);
						}
						& .fc-widget-content {
							border-color: lighten($border-color, 5%);
						}
					}
				}
			}
			& .fc-bg {
				& td {
					border-color: lighten($border-color, 5%);

					&.fc-highlight {
						background: lighten($dark, 65%);
					}
					&.fc-today {
						background: lighten($yellow, 43%);
					}

					&:first-child {
						border-left: none;
					}
				}
			}
			& .fc-content-skeleton {
				& .fc-day-number {
					font-weight: 600;
				}
				& .fc-event-container {
					& .fc-event {
						border: none;
						background: lighten($dark, 15%);
						font-weight: 600;
						color: $white;

						@include border-radius(0);

						&.fc-day-grid-event {
							padding: 1px 2px;
						}
					}
				}
			}
			& .fc-highlight-skeleton {
				& .fc-highlight {
					background: lighten($dark, 65%);
				}
			}
		}
	}
	& .fc-scroller {
		overflow: initial;
		height: auto !important;
	}
	& .fc-time,
	& .fc-axis {
		font-weight: 600;
	}
	& .fc-event,
	& a.fc-event {
		border: none;
		background: lighten($dark, 15%);
		font-weight: 600;
		color: $white;

		@include border-radius(0);

		&:hover,
		&:focus {
			color: $white;
			opacity: 0.85;
		}

		&.fc-day-grid-event {
			padding: 1px 2px;
		}
	}
}
.fc-event-list {
	& .fc-event {
		background: none;
		border: none;
		color: $dark;
		padding: 7px 10px 7px 35px;
		font-size: 12px;
		line-height: 16px;
		position: relative;
		background: $white;
		margin-bottom: 5px;

		@include border-radius($border-radius);

		& + .fc-event {
			margin-top: 0;
		}
		& .fc-event-icon {
			float: right;
			margin-left: 10px;

			& i {
				float: left;
				line-height: 18px;
			}
		}
		&.ui-draggable-dragging {
			border: 1px dashed $border-color;
		}
		&:before {
			content: '\f0b2';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 25px;
			text-align: center;
			padding-top: 7px;
			padding-bottom: 7px;
			background: lighten($dark, 75%);
			color: lighten($dark, 60%);
			font-size: 14px;

			@include fontawesome();
			@include border-radius($border-radius 0 0 $border-radius);
		}
		&:hover,
		&:focus {
			color: $dark;
			cursor: pointer;
			background: $white;

			&:before {
				color: lighten($dark, 30%);
			}
		}
	}
}

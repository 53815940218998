.pwstrength-progress {
	& .progress {
		height: 12px;

		@include border-radius($border-radius-lg * 10);

		& .progress-bar {
			min-width: 10% !important;
		}
	}
}

/* React Grid Layout Overrides */
// @import './../variables.scss';
// @import '../../../scss/styles.scss';
// @import 'node_modules/bootstrap/scss/bootstrap';

.react-grid-layout {
  margin: 0 (-$grid-gutter-width / 2);

  .react-grid-item.react-grid-placeholder {
    background: none;
    z-index: 2;
    transition-duration: 100ms;
    user-select: none;
    position: relative;
    opacity: 0.7;

    &:after {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      content: ' ';
      background: rgba($primary, 0.3);
      border-radius: 4px;
      border: 2px dashed $primary;
      height: calc(100% - #{$grid-gutter-width / 2});
      width: calc(100% - #{$grid-gutter-width});
    }
  }
}

.float-column {
  display: flex;
  flex-direction: column;

  .panel {
    flex: 1 1 auto;
    margin-bottom: $grid-gutter-width / 3;

    + .react-resizable-handle {
      bottom: $grid-gutter-width / 3;
      right: $grid-gutter-width / 3;
    }

    .panel-heading {
      cursor: move;
      user-select: none;
    }
  }
}

.float-column {
  [class^='col-'] {
  }

  &--size-lg {
    .col-md-4 {
    }
  }
}

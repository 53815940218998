body {
	& .daterangepicker {
		z-index: 1020;
		border: none;
		font-family: inherit;

		@include border-radius($border-radius-lg);
		@include box-shadow(0 6px 30px rgba($black, .2));
		@if $enable-rtl {
			direction: rtl;
			text-align: right;
		}

		&:before {
			display: none;
		}
		&:after {
			border-width: 7px;
			top: -7px;
			left: 10px;
		}

		& .drp-calendar {
			max-width: inherit;
			padding: 15px;

			& .calendar-table {
				& table {
					& thead {
						& tr {
							& th {
								min-width: auto;
								width: auto;
								height: auto;
								line-height: 1;
								padding: 7px 7px;
								color: $dark;
								font-weight: bold;
								border: none;

								&.prev,
								&.next {
									& span {
										border-color: $dark;
									}
									&:hover,
									&:focus {
										background: $light;
										color: $dark;
									}
								}
								&.month {
									& .yearselect,
									& .monthselect {
										border-color: $border-color;
										background: $white;

										@include border-radius($border-radius);
										@include transition(all .2s linear);

										&:focus {
											outline: none;
											border-color: $input-focus-border-color;

											@include box-shadow(0 0 0 0.125rem rgba($input-focus-border-color ,.3));
										}
									}
								}
							}
							&:last-child {
								& th {
									padding-bottom: 14px;
								}
							}
						}
					}
					& tbody {
						& tr {
							& td {
								min-width: auto;
								width: auto;
								height: auto;
								line-height: 1;
								padding: 7px 9px;
								font-weight: 600;
								color: $dark;
								border: none;

								&.available {
									@include border-radius($border-radius);

									&:hover,
									&:focus {
										background: $light;
									}
									&.in-range {
										background: lighten($form-component-active-bg, 40%);
										color: darken($form-component-active-color, 30%);

										@include border-radius(0);
									}
									&.active {
										background: $form-component-active-bg;
										color: $white;
									}
									&.start-date {
										@include border-radius($border-radius 0 0 $border-radius);
									}
									&.end-date {
										@include border-radius(0 $border-radius $border-radius 0);
									}
								}
								&.off {
									background: none;
									color: lighten($dark, 60%);
								}
							}
						}
					}
				}
			}
			&.left {
				padding: 10px !important;

				& .calendar-table {
					padding: 0 !important;
				}
			}
			&.right {
				padding: 10px !important;
				border-left: 1px solid $border-color;

				@include media-breakpoint-down(xs) {
					border-left: none;
					border-top: 1px solid $border-color;
				}
			}
		}
		& .drp-buttons {
			padding: 10px;
			border-color: $border-color;

			& .btn {
				@if $enable-rtl {
					margin-left: 0;
					margin-right: 8px;
				}
			}
			& .drp-selected {
				font-weight: 600;
				color: lighten($dark, 30%);
			}
		}
		& .ranges {
			display: none;
		}
		& select.monthselect {
			@if $enable-rtl {
				margin-right: 0;
				margin-left: 2%;
			}
		}
		&.show-ranges {
			& .ranges {
				position: relative;
				display: block;

				@if $enable-rtl {
					float: right;
				}
				@include media-breakpoint-down(md) {
					float: none;
					margin: 0;
					padding: 10px;
					border-bottom: 1px solid $border-color;
					overflow: scroll;
					max-width: 560px;

					@if $enable-rtl {
						float: none;
					}
				}

				& ul {
					@include media-breakpoint-down(md) {
						width: auto;
						white-space: nowrap;
						padding-right: 10px;

						@include display-flex();
						@include flex-wrap(nowrap);
					}

					& li {
						font-weight: 600;
						color: lighten($dark, 30%);

						@include media-breakpoint-down(md) {
							margin-right: 10px !important;

							@include border-radius($border-radius);
						}

						&:hover,
						&:focus {
							background: $light;
							color: $dark;
						}
						&.active {
							background: $form-component-active-bg;
							color: $white;
							margin-right: -1px;
						}
					}
				}
			}
			& .drp-calendar {
				&.left {
					border-left: 1px solid $border-color;

					@if $enable-rtl {
						border-left: none !important;
					}
					@include media-breakpoint-down(md) {
						border-left: none;
					}
				}
				&.right {
					@if $enable-rtl {
						border-right: 1px solid $border-color;
					}
					@include media-breakpoint-down(md) {
						@if $enable-rtl {
							border-right: none;
						}
					}
				}
			}
		}
	}
}

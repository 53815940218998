.register {
	&.register-with-news-feed {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		& .news-feed {
			position: fixed;
			left: 0;
			right: 500px;
			top: 0;
			bottom: 0;
			overflow: hidden;

			@include transform(translateZ(0));
			@if $enable-rtl {
				right: 0;
				left: 500px;
			}
			@include media-breakpoint-down(lg) {
				right: 400px;

				@if $enable-rtl {
					left: 400px;
					right: 0;
				}
			}
			@include media-breakpoint-down(sm) {
				position: relative;
				right: 0;
				display: none;

				@if $enable-rtl {
					left: 0;
				}
			}

			& .news-image {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: 0;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center;
			}
			& .news-caption {
				color: rgba($white, .85);
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				padding: 60px 60px 30px;
				font-size: 18px;
				letter-spacing: 0.25px;
				z-index: 20;
				font-weight: 300;
				background: -moz-linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
				background: -webkit-linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );

				@include media-breakpoint-down(lg) {
					padding: 45px 45px 30px;
					font-size: 16px;
				}

				& .caption-title {
					font-weight: 300;
					color: $white;
					font-size: 36px;

					@include media-breakpoint-down(lg) {
						font-size: 28px;
					}
				}
			}
		}
		& .right-content {
			min-height: 100%;
			background: $white;
			width: 500px;
			margin-left: auto;
			padding: 60px;

			@include display-flex();
			@include flex-direction-column();
			@include flex-justify-content(center);
			@if $enable-rtl {
				margin-left: 0;
				margin-right: auto;
			}
			@include media-breakpoint-down(lg) {
				width: 400px;
				padding: 45px;
			}
			@include media-breakpoint-down(sm) {
				width: auto;
			}
			@include media-breakpoint-down(xs) {
				padding: 30px;
			}
			& .register-content {
				width: auto;
			}
			& .register-header {
				position: relative;
				font-size: 32px;

				@include media-breakpoint-down(lg) {
					font-size: 24px;
				}

				& + .register-content {
					padding-top: 15px;
				}
				& small {
					color: lighten($dark, 30%);
					display: block;
					font-size: 14px;
					margin-top: 10px;
					line-height: 20px;

					@include media-breakpoint-down(lg) {
						font-size: 12px;
						line-height: 18px;
					}
				}
			}
		}
	}
}

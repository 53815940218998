.chats {
	list-style-type: none;
	margin: 0;
	padding: 12px 15px 15px;

	& > li {
		& + li {
			margin-top: 15px;

			@include clearfix();
		}
	}
	& > div {
		& + div {
			margin-top: 15px;

			@include clearfix();
		}
	}
	& .name {
		color: $dark;
		display: block;
		margin-bottom: 5px;
		font-weight: 600;
		text-decoration: none;

		& .label {
			font-size: 8px;
			padding: 1px 4px 2px;
			margin-left: 3px;
			position: relative;
			top: -1px;
		}
	}
	& .date-time {
		font-size: 10px;
		display: block;
		float: right;
		color: lighten($dark, 30%);
		margin-top: 3px;
		font-weight: 600;

		@if $enable-rtl {
			float: left;
		}
	}
	& .image {
		float: left;
		width: 60px;
		height: 60px;
		overflow: hidden;

		@include border-radius($border-radius);
		@if $enable-rtl {
			float: right;
		}

		& img {
			max-width: 100%;
		}
		& + .message {
			margin-left: 75px;

			@if $enable-rtl {
				margin-left: 0;
				margin-right: 75px;
			}
		}
	}
	& .message {
		padding: 7px 12px;
		font-size: 12px;
		position: relative;
		background: $white;

		@include border-radius($border-radius * 3);

		&:before {
			content: '';
			position: absolute;
			left: -13px;
			top: 15px;
			border: 7px solid transparent;
			border-right-color: $white;

			@if $enable-rtl {
				left: auto;
				right: -13px;
				border-right-color: transparent;
				border-left-color: $white;
			}
		}
	}
	& .right {
		& .name {
			text-align: right;

			@if $enable-rtl {
				text-align: left;
			}
		}
		& .date-time {
			float: left;

			@if $enable-rtl {
				float: right;
			}
		}
		& .image {
			float: right;

			@if $enable-rtl {
				float: left;
			}

			& + .message {
				margin-left: 0;
				margin-right: 75px;

				@if $enable-rtl {
					margin-right: 0;
					margin-left: 75px;
				}
			}
		}
		& .message {
			text-align: right;

			&:before {
				left: auto;
				right: -13px;
				border-right-color: transparent;
				border-left-color: $white;

				@if $enable-rtl {
					right: auto;
					left: -13px;
					border-right-color: $white;
					border-left-color: transparent;
				}
			}
		}
	}
}
.panel-body .chats {
	padding: 0;
}
.slimScrollDiv .chats {
	padding-right: 15px;

	@if $enable-rtl {
		padding-right: 0;
		padding-left: 15px;
	}
}

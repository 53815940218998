.ui-widget {
	font-family: inherit;

	&.ui-autocomplete {
		padding: 5px 0;
		border: none;
		font-size: 12px;

		@include box-shadow(0 8px 16px rgba($black, .15));
		@include border-radius(0 0 $border-radius $border-radius);

		& .ui-menu-item-wrapper {
			padding: 5px 12px;

			&.ui-state-active {
				background: $form-component-active-bg;
				border: none;
				color: $white;
				margin: 0;

				@include border-radius(0);
			}
		}
	}
}

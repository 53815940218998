.note {
	margin-bottom: 20px;
	border-left: 3px solid;
	position: relative;

	@include border-radius($border-radius);
	@include display-flex();
	@include flex(1);

	& .note-icon {
		width: 80px;
		font-size: 56px;

		@include display-flex();
		@include flex-align(center);
		@include flex-justify-content(center);
	}
	& .note-content {
		padding: 15px;
		@include flex(1);

		& h1,
		& h2,
		& h3,
		& h4,
		& h5,
		& h6 {
			color: inherit;
		}

	}
	&.note-with-right-icon {
		border-left: none;
		border-right: 3px solid;
	}

	@each $color, $value in $theme-colors {
		&.note-#{$color} {

			@if $color == 'primary' {
				background: lighten($blue, 10%);
				color: darken($blue, 30%);
			}
			@if $color == 'light' {
				background: $white;
				color: $dark;
			}
			@if $color != 'primary' and $color != 'light' {
				border-color: $value;
				background: lighten($value, 10%);
				color: darken($value, 30%);
			}
		}
	}
	@each $color, $value in $colors {
		&.note-#{$color} {
			border-color: $value;
			background: lighten($value, 15%);
		}
	}
}

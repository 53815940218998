.bootstrap-timepicker-widget {
	& table {
		& tbody {
			& tr {
				& td {
					& .bootstrap-timepicker-hour,
					& .bootstrap-timepicker-minute,
					& .bootstrap-timepicker-meridian {
						width: 40px;
						height: 34px;
						border: 1px solid $border-color;
						padding: 6px;
						background: $white;
						color: $dark;

						@include box-shadow(none);
						@include transition(all .2s linear);
						@include border-radius($border-radius);

						&:focus {
							border-color: $input-focus-border-color;
							outline: none;

							@include box-shadow(0 0 0 0.125rem rgba($input-focus-border-color, .3));
						}
					}
					& .glyphicon {
						@include fontawesome();

						&.glyphicon-chevron-up {
							&:before {
								content: '\f077';
							}
						}
						&.glyphicon-chevron-down {
							&:before {
								content: '\f078';
							}
						}
					}
					& a {
						border: none;
						color: lighten($dark, 30%);
						padding: 5px;

						@include border-radius($border-radius !important);

						&:hover,
						&:focus {
							background: lighten($dark, 70%);
							color: $dark;
						}
					}

					&.separator {
						padding: 0 5px;
					}
				}
			}
		}
	}
	&.dropdown-menu {
		padding: 5px !important;

		@include border-radius($border-radius-lg !important);
		@include box-shadow(0 6px 30px rgba($black, .2) !important);
	}
	&:before {
		display: none !important;
	}
	&:after {
		left: 12px !important;
		top: -7px !important;
		border-width: 7px !important;
	}
	&.timepicker-orient-bottom {
		&:after {
			top: auto !important;
			bottom: -7px;
		}
	}
}

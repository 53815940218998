.sidebar {
  width: $sidebar-width;
  position: absolute;
  top: 50px;
  bottom: 0;
  padding: 0px 16px;
  background: #eaeaea;
  z-index: $sidebar-zindex;

  @include transform(translateZ(0));

  @if $enable-rtl {
    left: auto;
    right: 0;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    padding: 16px;
    top: 92px;
    left: 100vw * -1;
    z-index: $header-zindex + 1;

    @if $enable-rtl {
      left: auto;
      right: $sidebar-width * -1;
    }
  }

  & .nav {
    display: block;
    padding-left: 20px;
    padding-right: 20px;

    &>li {
      position: relative;

      @include clearfix();

      &>a {
        padding: 6px 12px;
        line-height: 20px;
        color: $dark;
        display: block;
        text-decoration: none;
        font-weight: 600;

        @include clearfix();
        @include border-radius($border-radius);

        & i {
          float: left;
          margin-right: 10px;
          width: 14px;
          text-align: center;
          line-height: 20px;
          font-size: 14px;

          @if $enable-rtl {
            float: right;
            margin-right: 0;
            margin-left: 10px;
          }

          &[class^='ion-'] {
            margin-right: 8px;
            width: 18px;
            font-size: 18px;
            margin-left: -2px;

            @if $enable-rtl {
              margin-right: -2px;
              margin-left: 8px;
            }
          }
        }

        & .icon-img {
          float: left;
          margin-right: 10px;
          margin-top: 3px;
          width: 14px;
          height: 14px;
          overflow: hidden;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include border-radius($border-radius);
          @include display-flex();
          @include flex-align(center);

          @if $enable-rtl {
            float: right;
            margin-right: 0;
            margin-left: 10px;
          }

          & img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: 0 auto;
          }
        }

        & .badge {
          padding: 5px 8px;
          background: $dark-darker;
          font-weight: 600;
          font-size: 10px;
        }

        & .label {
          padding-top: 0;
          padding-bottom: 0;
          margin-left: 8px;

          &.label-theme {
            background: $pink;

            @include gradient-enabled {
              @include gradient-linear(to bottom,
                (lighten($pink, 5%) 0%, darken($pink, 5%) 100%));
            }
          }

          @if $enable-rtl {
            margin-left: 0;
            margin-right: 8px;
          }
        }

        &>span {
          @include display-flex();
          @include flex(1);
          @include flex-wrap(wrap);
        }

        &:hover,
        &:focus {
          background: $white;
          color: $dark;

          @include media-breakpoint-down(sm) {
            background: $silver;
          }
        }
      }

      &.expand,
      &.expanding {
        &>a {
          background: $white;

          @include media-breakpoint-down(sm) {
            background: mix($dark, $silver, 5%);
          }
        }
      }

      &.active {
        &>a {
          position: relative;
          z-index: 10;
          color: $white;
          background: $primary;

          @include gradient-enabled {
            @include gradient-linear(to bottom,
              (lighten($primary, 5%) 0%, darken($primary, 5%) 100%));
          }

          & .label,
          & .badge {
            color: $white;
            background: rgba($black, 0.65);

            @include gradient-enabled {
              color: $white;
              background: rgba($black, 0.65);
            }
          }
        }
      }

      &.nav-header {
        margin: 0;
        padding: 15px 0 3px;
        line-height: 20px;
        font-size: 11px;
        color: $dark;
        font-weight: 600;

        & a {
          padding: 0;
          margin: 0;
          display: inline;

          &:hover,
          &:focus {
            background: none;
            color: $dark;
          }

          & i {
            float: none;
            margin: 0;
          }
        }
      }

      &.nav-profile {
        padding: 20px;
        color: $dark;
        background: none;
        overflow: hidden;
        border-bottom: 1px solid rgba($dark, 0.15);

        & a {
          padding: 0;
        }

        &>a {
          margin: -20px;
          padding: 20px 0;
          display: block;
          color: $dark;
          font-weight: 600;
          background: none;

          &:hover {
            background: none;

            & .cover {
              &.with-shadow {
                &:before {
                  opacity: 0.75;
                }
              }
            }
          }
        }

        & .image {
          width: 34px;
          height: 34px;
          margin-bottom: 10px;
          overflow: hidden;
          position: relative;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include display-flex();
          @include flex-align(center);
          @include border-radius($border-radius-lg * 5);

          & img {
            max-width: 100%;
            max-height: 100%;
          }

          &.image-icon {
            & i {
              float: none;
              display: block;
              font-size: 24px;
              text-align: center;
              width: auto;
              margin: 0 auto -12px;
            }
          }
        }

        & .info {
          font-size: 14px;
          position: relative;

          & .caret {
            margin-top: 2px;
          }

          & small {
            display: block;
            color: rgba($dark, 0.75);
            font-weight: 600;
            font-size: 12px;
            margin-bottom: -5px;
          }
        }
      }

      &.nav-search {
        padding: 20px 0;

        &+li {
          padding-top: 0;
        }

        & .form-control {
          background: none;
          border: none;
          padding-left: 0;
          padding-right: 0;
          border-bottom: 1px solid rgba($dark, 0.2);
          color: $dark;
          font-weight: 600;

          @include border-radius(0);

          &:focus {
            border-color: rgba($dark, 0.5);

            @include box-shadow(none);
          }
        }
      }

      &.nav-widget {
        padding: 10px 0;

        & i {
          margin-right: auto;
        }
      }

      & .text-theme {
        @if $enable-rtl {
          margin-right: 5px;
        }

        @else {
          margin-left: 5px;
        }
      }
    }

    & .has-sub {
      &.active {
        &>.sub-menu {
          display: block;
        }
      }
    }

    & .sub-menu {
      &>li {
        &>a {
          color: $dark;
          font-weight: 600;

          & .caret {
            margin-top: -1px;
            left: -5px;
          }
        }
      }
    }

    &.nav-profile {
      padding: 10px 0;
      border-bottom: 1px solid rgba($dark, 0.15);
      display: none;

      &.active {
        display: block;
      }
    }
  }

  & li {
    &>a {
      & .caret {
        display: block;
        float: right;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 11px;
        border: none;

        @include fontawesome();

        @if $enable-rtl {
          float: left;
        }

        &:before {
          content: '\f054';
          display: block;
          text-align: center;
          opacity: 0.5;

          @include transition(all 100ms linear);

          @if $enable-rtl {
            content: '\f053';
          }
        }
      }
    }

    &.active,
    &.expanding,
    &.expand {
      &>a {
        & .caret {
          &:before {
            opacity: 1;

            @include transform(rotate(90deg));

            @if $enable-rtl {
              @include transform(rotate(-90deg));
            }
          }
        }
      }
    }

    &.closed,
    &.closing {
      &>a {
        & .caret {
          &:before {
            @include transform(rotate(0deg));
          }
        }
      }
    }
  }

  & .sub-menu {
    list-style-type: none;
    padding: 5px 0 10px 21px;
    margin: 0;
    position: relative;
    display: none;

    @if $enable-rtl {
      padding-left: 0;
      padding-right: 39px;
    }

    &>li {
      position: relative;

      &>a {
        padding: 3px 12px 3px 15px;
        display: block;
        color: $dark;
        text-decoration: none;
        position: relative;

        @if $enable-rtl {
          padding-right: 15px;
          padding-left: 12px;
        }

        &:hover,
        &:focus {
          color: $primary;
        }
      }

      &.active {
        &>a {
          color: $primary;
        }
      }
    }

    & .sub-menu {
      padding: 0 0 0 15px;
      background: none;

      @if $enable-rtl {
        padding-left: 0;
        padding-right: 15px;
      }
    }
  }

  & .sidebar-minify-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    text-decoration: none;
    float: right;
    padding: 8px 12px;
    background: #eaeaea;
    color: $dark;

    @include border-radius($border-radius * 5 0 0 $border-radius * 5);

    @if $enable-rtl {
      float: left;

      @include border-radius(0 $border-radius * 5 $border-radius * 5 0);
    }

    & i {
      margin: 0 !important;
      color: $dark;
    }

    &:hover,
    &:focus {
      background: none !important;
    }
  }

  & .slimScrollDiv {
    & .slimScrollBar {
      display: none !important;
    }
  }
}

.sidebar-bg {
  width: $sidebar-width;
  background: none;
  position: fixed;
  top: 57px;
  bottom: 0;
  z-index: $sidebar-zindex - 10;

  @if $enable-rtl {
    left: auto;
    right: 0;
  }

  @include media-breakpoint-down(sm) {
    width: 100vw;
    padding-top: 0;
    top: 92px;
    left: 100vw * -1;
    z-index: $header-zindex;
    background: $white;

    @if $enable-rtl {
      left: auto;
      right: $sidebar-width * -1;
    }
  }
}

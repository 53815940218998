.carousel {
	& .carousel-control {
		& .fa {
			position: absolute;
			top: 50%;
			display: block;
			width: 30px;
			height: 30px;
			margin-top: -15px;
			text-align: center;
			line-height: 30px;
			margin-left: -15px;
			z-index: 5;

			@if $enable-rtl {
				margin-left: 0;
				margin-right: -15px;
			}
		}
		&.left {
			& .fa {
				margin-left: 15px;

				@if $enable-rtl {
					margin-left: 0;
					margin-right: 15px;
				}
			}
		}
	}
}

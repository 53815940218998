.page-sidebar-minified {
  & .sidebar {
    width: $sidebar-minified-width + 16px;
    position: absolute;

    & .nav {
      padding-left: 0;
      padding-right: 0;

      &>li {
        &>a {
          padding: 10px 30px;
          display: flex;
          justify-content: center;

          @include border-radius(0);

          & span {
            display: none;
          }

          & i {
            margin: 0;
          }
        }

        &.active {
          &>a {
            background: none;
            color: $primary;
          }
        }

        &.expand,
        &.expanding {
          &>a {
            background: none;
          }
        }
      }

      & li {
        &.has-sub {
          &>.sub-menu {
            display: none !important;
          }
        }
      }
    }

    & .nav-profile {
      display: none;

      &.expand {
        display: none !important;
      }
    }

    & .nav-header,
    & .nav-search {
      display: none;
    }

    & .caret {
      position: absolute;
      top: 10px;

      @if $enable-rtl {
        left: 5px;
      }

      @else {
        right: 5px;
      }

      &:before {
        @include transform(rotate(0deg) !important);
      }
    }

    & .slimScrollDiv {

      & .slimScrollBar,
      & .slimScrollRail {
        display: none !important;
      }
    }

    &.sidebar-right {

      & .slimScrollDiv,
      & .slimScrollDiv>div {
        overflow: hidden !important;
      }

      & .slimScrollBar {
        display: block !important;
      }
    }
  }

  & .sidebar-bg {
    width: $sidebar-minified-width + 16px;
  }

  & .content {
    @if $enable-rtl {
      margin-right: $sidebar-minified-width;
    }

    @else {
      margin-left: $sidebar-minified-width + 16px;
    }

    @include media-breakpoint-down(sm) {
      @if $enable-rtl {
        margin-right: 0;
      }

      @else {
        margin-left: 0;
      }
    }
  }

  & .sidebar-minify-btn {
    padding: 8px 0px !important;

    & i {
      &:before {
        content: '\f101';
      }
    }
  }

  & .map {
    @include media-breakpoint-up(md) {
      @if $enable-rtl {
        right: $sidebar-minified-width;
      }

      @else {
        left: $sidebar-minified-width;
      }
    }
  }

  &.page-with-right-sidebar {
    @include media-breakpoint-up(md) {
      @if $enable-rtl {
        right: 0;
        left: $sidebar-minified-width;
      }

      @else {
        right: $sidebar-minified-width;
        left: 0;
      }
    }

    & .content {
      @if $enable-rtl {
        margin-right: 0;
        margin-left: $sidebar-minified-width;
      }

      @else {
        margin-right: $sidebar-minified-width;
        margin-left: 0;
      }

      @include media-breakpoint-down(sm) {
        @if $enable-rtl {
          margin-left: 0;
        }

        @else {
          margin-right: 0;
        }
      }
    }

    & .sidebar {
      & .nav {
        &>li {
          &>a {

            & .icon-img,
            & i {
              float: none;
              width: auto;

              @if $enable-rtl {
                margin: 0 5px 0 0;
              }

              @else {
                margin: 0 0 0 5px;
              }
            }

            & .caret {
              position: absolute;

              @if $enable-rtl {
                left: auto;
                right: 3px;
              }

              @else {
                right: auto;
                left: 3px;
              }

              &:before {
                content: '\f053';
              }
            }
          }

          &>.sub-menu {
            @if $enable-rtl {
              padding: 10px 0 10px 30px;
            }

            @else {
              padding: 10px 30px 10px 0;
            }
          }
        }
      }
    }

    & .sidebar-minify-btn {
      padding: 8px 0px !important;

      & i {
        &:before {
          content: '\f100';
        }
      }
    }
  }

  &.page-sidebar-fixed {
    & .sidebar {
      position: fixed;

      & .slimScrollDiv,
      & .slimScrollDiv>div {
        overflow: hidden !important;
      }
    }
  }

  &:not(.page-sidebar-fixed) {
    & .sidebar {

      & .slimScrollDiv,
      & .slimScrollDiv>div {
        overflow: visible !important;
        height: auto !important;
      }
    }
  }

  & .sidebar-nav__infos {
    display: none;
  }

  & .sidebar-nav__avatar {
    width: 44px;
    height: 44px;

    & p {
      font-size: 20px;
    }
  }
}

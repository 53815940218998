html {
  --primary-color: #7e2dc2;
  --secondary-color: #00eae0;
  --foreground-color: #555;
  --foreground-alt-color: #f2f2f2;
  --background-color: #ffffff;
  --card-color: #ddd;
  --item-color: #eee;

  --border-color: #aaa;
  --label-color: #777777;
}

.titleImage {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0px 0px 16px;
  padding: 0px;
}

.titleImage > img {
  max-width: 100px;
  max-height: 100px;
}

.titleImage > h1 > span {
  white-space: nowrap;
  margin: 0px 8px;
}

.titleImageMobile {
  position: relative;
  display: none;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0px 0px 16px;
  padding: 0px;
}

.titleImageMobile > div {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.titleImageMobile > .title {
  align-items: flex-start;
}

.titleImageMobile > div > img {
  max-width: 80px;
  max-height: 80px;
  margin-bottom: 8px;
}

@media screen and (max-width: 920px) {
  .titleImage {
    display: none;
  }

  .titleImageMobile {
    display: flex;
  }
}

.form-input {
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.form-input > input,
select:not([multiple]),
select:not([multiple]) > option {
  width: 100%;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  height: 2.8em;
  padding: 4px 12px 0;
  background-color: var(--background-color);
  color: var(--foreground-color);
}

.form-input > input:focus {
  outline-color: var(--primary-color);
}

.form-input > select:focus {
  border: 2px solid var(--primary-color);
  outline-color: var(--primary-color);
}

.form-input > label {
  position: absolute;
  top: -0.5em;
  font-size: 1em;
  line-height: 1em;
  font-weight: 600;
  margin-left: 8px;
  padding: 0 8px;
  color: var(--label-color);
  background-color: var(--background-color);
}

.form-input-multiple-select {
  position: relative;
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 32px;
}

.form-input-multiple-select > select {
  width: 100%;
  border: 2px solid var(--border-color);
  border-radius: 4px;
  padding: 16px 12px 12px;
  background-color: var(--background-color);
  color: var(--foreground-color);

  display: flex;
}

.form-input-multiple-select > select:focus {
  border: 2px solid var(--primary-color);
  outline-color: var(--primary-color);
}

.form-input-multiple-select > select > option {
  border: 2px solid var(--border-color);
  border-radius: 4px;
  min-height: 3em;
  padding: 12px;
  background-color: var(--background-color);
  color: var(--foreground-color);
  white-space: break-spaces;
  cursor: pointer;
}

.form-input-multiple-select > label {
  position: absolute;
  top: -0.5em;
  font-size: 1em;
  line-height: 1em;
  font-weight: 600;
  margin-left: 8px;
  padding: 0 8px;
  color: var(--label-color);
  background-color: var(--background-color);
}

.event-info {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 32px;
  padding: 0;
  font-size: 16px;
}

.event-info-field {
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

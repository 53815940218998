.nav {
	&.nav-tabs {
		& .nav-item {
			& .nav-link {
				padding: 10px 15px;
				font-weight: 600;
				color: lighten($dark, 30%);

				&.active,
				&:hover,
				&:focus {
					color: $dark;
				}
			}
		}
		&.nav-tabs-inverse {
			background: $dark-darker;

			@include border-radius($border-radius $border-radius 0 0);

			& .nav-item {
				& .nav-link {
					color: lighten($dark, 60%);

					&.active {
						color: $dark;
					}
				}
			}
		}

		& + .tab-content {
			padding: 15px;
			background: $white;
			margin-bottom: 20px;

			@include border-radius(0 0 $border-radius $border-radius);
		}
	}

	&.nav-pills {
		& .nav-item {
			& .nav-link {
				padding: 10px 15px;
				font-weight: 600;
				color: lighten($dark, 30%);

				&:hover,
				&:focus {
					color: $dark;
				}
				&.active {
					color: $white;
				}
			}
		}
	}
}

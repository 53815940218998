.bootstrap-calendar {
  & .calendar {
    background: $white;
    border: none;
    padding: 7px 0 10px;

    @include border-radius(0);

    & .table {
      &.header {
        padding: 0;
        margin: 0 0 2px;
        position: relative;

        @include box-shadow(none);

        & td {
          width: 45px;
          padding: 5px 15px;
          vertical-align: middle;
          border: none;

          &.year {
            width: calc(100% - 90px);
          }

          & .visualmonthyear {
            height: 100%;
            font-size: 16px;
            font-weight: 600;

            @include display-flex();
            @include flex-align(center);
            @include flex-justify-content(center);
          }
          & .icon-arrow-right,
          & .icon-arrow-left {
            cursor: pointer;
            font-size: 14px;
            height: 100%;
            color: lighten($dark, 60%);

            @include display-flex();
            @include flex-align(center);
            @include flex-justify-content(center);
            @include fontawesome();

            &:hover,
            &:focus {
              color: $dark;
            }
          }
          & .icon-arrow-left {
            &:before {
              content: '\f060';
            }
          }
          & .icon-arrow-right {
            &:before {
              content: '\f061';
            }
          }
        }
      }
      &.daysmonth {
        margin: 0;

        & tr {
          & td {
            border: none;
            padding: 7px 10px;
            font-weight: 600;
            color: lighten($dark, 30%);

            & a {
              display: block;
              text-shadow: none;
              width: 26px;
              height: 26px;
              line-height: 26px;
              margin: -4px auto;

              @include border-radius($border-radius * 5);
            }

            &.event {
              background: none;

              @include box-shadow(none);

              & a {
                color: $white;
              }
            }
          }

          &.week_days {
            & td {
              background: none;
              color: lighten($dark, 15%);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
